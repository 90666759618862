import axiosInstance from "../utils/axios";
import { UserAgentApplication, AuthenticationParameters, InteractionRequiredAuthError } from "msal";
import { User, UserTokenPayload } from "@ticketrz/api-types";

/**
 * Retrieve azure active directory access token with `user.read` scope
 */
export const getAadAccessToken = async () => {
  const msalInstance = new UserAgentApplication({
    auth: {
      clientId: process.env.REACT_APP_AAD_CLIENT_ID || "",
      authority: process.env.REACT_APP_AAD_AUTHORITY || "",
      redirectUri: process.env.REACT_APP_AAD_REDIRECT_URI || "",
    },
  });

  const tokenRequest: AuthenticationParameters = {
    scopes: ["user.read"],
  };

  if (!msalInstance.getAccount()) {
    await msalInstance.loginPopup(tokenRequest);
  }

  const { accessToken } = await msalInstance.acquireTokenSilent(tokenRequest)
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenPopup(tokenRequest);
      }
      throw error;
    });
  return accessToken;
}

export const decodeJwt = (token: string): UserTokenPayload => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export const checkEmail = (email: string) => axiosInstance.get<Pick<User, 'email' | 'phone'>>('/auth/login-methods', {
  params: {
    email,
  }
}).then(response => response.data);

export const sendEmailCodeRequest = (email: string) => axiosInstance.post<void>('/auth/code-request', {
  email,
}).then(response => response.data);

export const sendSmsCodeRequest = (email: string) => {
  // TODO:
  throw new Error('Not implemented');
}

export const sendCode = (email: string, code: string,) => axiosInstance.post<{accessToken: string}>('/auth/code', {
  email,
  code,
}).then(response => response.data);

/**
 * Authenticate an agent with azure active directory
 * @param aadAccessToken azure active directory access token with `user.read` scope
 */
export const authWithAad = (aadAccessToken: string) => axiosInstance.post<{ accessToken: string }>(
  '/auth/aad',
  undefined,
  {
    headers: {
      Authorization: `Bearer ${aadAccessToken}`
    }
  }
)
  .then(response => response.data);