import React from 'react'
import Avatar from "../../Avatar";
import { FormattedDate } from "react-intl";
import { ThreadEvent, User } from "@ticketrz/api-types";

const StatusUpdatedEvent = ({ event, projectId, users }: { event: ThreadEvent, projectId: number, users: User[] }) => {

  const dotStyle: React.CSSProperties = {
    left: '-9px',
    top: '-9px',
  }

  const user = users.find(u => u.id === event.userId);

  return (<div className="border-l-2 border-gray-500 relative pl-6 py-4 md:py-6">
    <div style={dotStyle} className="absolute flex items-center">
      <div className="bg-white rounded-full w-4 h-4 flex items-center justify-center">
        <span className="bg-orange rounded-full w-2 h-2">&nbsp;</span>
      </div>
      <p className="ml-2 text-gray-500 text-xs md:text-sm">
        <FormattedDate
          value={event.createdAt}
          year="numeric"
          day="numeric"
          month="long"
          hour="numeric"
          minute="numeric"
        />
      </p>
    </div>
    <div className={`${event.payload?.from === 'OPEN' ? 'bg-red-200' : 'bg-green-200'} rounded shadow-lg`}>
      <div className="flex justify-between p-2 md:p-4 ">
        <div>
          {event.payload?.from === 'OPEN' ?
          <p className="font-bold text-red-700">La discussion a été fermé</p>:
          <p className="font-bold text-green-700">La discussion a été réouverte</p>
        }
        </div>
        <div className="flex items-center">
          {user ? <Avatar firstname={user.firstname} lastname={user.lastname} size="sm" /> : null}
          <p className="ml-2 hidden md:block text-sm">{user?.firstname} {user?.lastname}</p>
        </div>
      </div>
    </div>
  </div>)
}

export default StatusUpdatedEvent;