import React, { useState, useContext } from 'react'
import { ReactComponent as Logo } from "../assets/logo-yabawt.svg";
import { ReactComponent as MenuIcon } from "../icons/menu.svg";
import { ReactComponent as CrossIcon } from "../icons/cross.svg";
import { NavLink } from 'react-router-dom';
import AccountMenu from "./menu/AccountMenu";
import ResponsiveMenu from "./menu/ResponsiveMenu";
import { AuthContext } from "./auth/AuthContextProvider";
import { decodeJwt } from "../common/auth/auth.service";


const Header = () => {
  const [showResponsiveMenu, setshowResponsiveMenu] = useState(false)
  const { state } = useContext(AuthContext);
  const userInfo = state.accessToken ? decodeJwt(state.accessToken) : null;

  const betaStyle: React.CSSProperties = {
    right: "-10px",
    bottom: "5px",
  }

  return (<div className="flex flex-items-center justify-between h-16 border-b border-gray-300">
    <NavLink to="/threads" className="relative flex flex-col justify-center items-center">
      <Logo className="w-24 ml-4" />
      <p style={betaStyle} className="absolute font-bold text-sm text-orange">Beta</p>
    </NavLink>
    <div className="hidden md:flex h-100">
      <NavLink to="/threads" activeClassName="border-b-2 border-orange text-orange" className="outline-none flex flex-col justify-center">
        <span className="font-bold px-10">Echanges</span>
      </NavLink>
      <NavLink to="/projects" activeClassName="border-b-2 border-orange text-orange" className="outline-none flex flex-col justify-center">
        <span className="font-bold px-10">Projets</span>
      </NavLink>
    </div>
    <div className="hidden md:flex items-center">
      {userInfo ? <AccountMenu firstname={userInfo.firstname} lastname={userInfo.lastname} className="relative flex justify-end w-48 mr-6" /> : null}
    </div>
    <div className="md:hidden relative flex items-center mr-4">
      {!showResponsiveMenu ?
        <div className="bg-orange text-white rounded-full w-8 h-8 flex items-center justify-center" onClick={() => setshowResponsiveMenu(true)}>
          <MenuIcon className="fill-current cursor-pointer w-4 h-4" />
        </div>
        :
        <div className="bg-orange text-white rounded-full w-8 h-8 flex items-center justify-center" onClick={() => setshowResponsiveMenu(false)}>
          <CrossIcon className="fill-current cursor-pointer" />
        </div>
      }
    </div>
    {
      showResponsiveMenu && userInfo ?
        <ResponsiveMenu userInfo={userInfo} handleRouteClick={() => setshowResponsiveMenu(false)} />
        : null
    }
  </div>)
}

export default Header;