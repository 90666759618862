import React, { useState, useEffect } from 'react'
import Avatar from "../Avatar";
import ThreadAttachment from "./ThreadAttachment";
import { Thread, File, User } from "@ticketrz/api-types";
import { getThreadAttachments } from "../../common/services/thread.service";
import Button from "../forms/Button";
import Swal from 'sweetalert2';
import ThreadStatus from "./ThreadStatus";

const ThreadInformation = ({ thread, handleClosedThread, users }: { users: User[], thread: Thread | null, handleClosedThread: () => void }) => {
  const [attachments, setAttachments] = useState<Array<File & { signedPreviewUrl: string }>>([]);

  useEffect(() => {
    if (thread) {
      getThreadAttachments(thread.id.toString()).then(response => setAttachments(response.list));
    };
  }, [thread])

  const closeThread = async () => {
    const result = await Swal.fire({
      title: 'Fermer la discussion',
      text: 'Vous pourrez toujours réouvrir la discussion avec un nouveau message',
      icon: 'question',
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#F84601',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
    })
    if (result.value) {
      handleClosedThread();
    }
  }

  return (<div className="flex flex-col inline-flex md:justify-between pt-4 w-full">
    <div className="flex md:justify-end mb-8">
      <Button text="Fermer la discussion" bType="default" icon="box" onClick={() => closeThread()} />
    </div>
    <p className="text-gray-700 uppercase text-xs font-bold tracking-widest border-b border-gray-300 pb-1 mb-4">Projet</p>
    <p className="uppercase text-gray-600 text-xs">{thread?.project?.client}</p>
    <p className="uppercase font-bold mb-4 md:mb-10">{thread?.project?.name}</p>
    <p className="text-gray-700 uppercase text-xs font-bold tracking-widest border-b border-gray-300 pb-1 mb-4">
      Statut
    </p>
    <div className="mb-4 md:mb-12">
      <div className="inline-block">
        <ThreadStatus status={thread?.status} />
      </div>
    </div>
    <p className="text-gray-700 uppercase text-xs font-bold tracking-widest border-b border-gray-300 pb-1 mb-4">Participants</p>
    <div className="flex flex-wrap items-center mb-4 md:mb-12">
      {users.map(user =>
        <div key={user.id} className="bg-white rounded-full px-2 py-1 flex items-center border">
          <Avatar firstname={user.firstname} lastname={user.lastname} size="sm" />
          <p className="ml-2 text-sm">{user.firstname} {user.lastname}</p>
        </div>
      )}
    </div>
    <p className="text-gray-700 hidden md:block uppercase text-xs font-bold tracking-widest border-b border-gray-300 pb-1 mb-4">Pieces jointes</p>
    <ThreadAttachment projectId={thread?.projectId} attachments={attachments} />
  </div>);
};

export default ThreadInformation;