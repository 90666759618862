import React from 'react'
import { ReactComponent as EnvelopeIcon } from "../../icons/envelope.svg";
import Button from "../forms/Button";

const SelectSendTypeStep = ({ email, error, handleNext }: { email: string, error: string | null, handleNext: (e: React.FormEvent<HTMLFormElement>) => void }) => {
  return (<div className="w-full">
    <form onSubmit={(e) => handleNext(e)}>
      <div className="flex items-center border border-gray-300 rounded w-full px-4 py-4">
        <div className="w-1/6 flex items-center">
          <input type="radio" id="emailRadio" hidden defaultChecked />
          <label htmlFor="emailRadio">
            <div className="border border-gray-800 rounded-full h-4 w-4 flex items-center justify-center">
              <div className="bg-gray-800 rounded-full h-2 w-2">&nbsp;</div>
            </div>
          </label>
        </div>
        <div className="w-4/6 leading-tight">
          <p className="text-gray-600">{email}</p>
          <p className="font-bold">Envoyer un code par mail</p>
        </div>
        <div className="w-1/6 flex items-center justify-end">
          <EnvelopeIcon className="fill-current text-orange-light w-10 h-10" />
        </div>
      </div>
      {error ? <div className="bg-red-200 rounded px-4 py-2 my-2 text-red-700">
        {error}
      </div> : null}
      <div className="flex items-center justify-center mt-6">
        <Button text="Envoyer code" className="w-48" type="submit" bType="primary" />
      </div>
    </form>
  </div>)
}

export default SelectSendTypeStep;