import { ThreadSortAction, ThreadSortState } from '../common/types';
import { StateCreator } from 'zustand';

export type ThreadSortSlice = ThreadSortState & ThreadSortAction;

const createThreadSortSlice: StateCreator<ThreadSortState & ThreadSortAction> = (
  (set) => ({
    sort: {
      order: "DESC",
      sort: "createdAt",
    },

    setSort: (payload) => set(() => ({
      ...payload
    })),
  })
)

export default createThreadSortSlice