import React from 'react'
import { ReactComponent as Magnifier } from "../icons/magnifier.svg";

const Search = (
  { className, handleChange }:
    { className?: string, handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void }
) => {
  return (<div className={`${className} relative max-w-lg w-full z-0`}>
    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
      <Magnifier className="fill-current text-gray-600 mr-4" />
    </div>
    <input
      type="text"
      className="block bg-gray-200 pl-10 pr-4 py-2 w-full rounded-lg border border-transparent focus:bg-white focus:border-gray-400"
      placeholder="Rechercher..."
      onChange={(e) => handleChange(e)}
    />
  </div>)
}

export default Search;