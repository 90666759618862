import React, { FunctionComponent, createContext, useReducer, Dispatch, useEffect } from "react";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { getItemFromLocalStorage } from "../../common/utils/get-item-from-local-storage";
import { AuthState, AuthAction, authReducer } from "../../common/auth/auth.reducer";
import axiosInstance from "../../common/utils/axios";
import { AxiosError } from "axios";

export const AuthContext = createContext<{ state: AuthState; dispatch: Dispatch<AuthAction> }>({
  state: {},
  dispatch: () => { },
});

const initialState: AuthState | undefined = getItemFromLocalStorage("authState");

/**
 * Load state from localstorage and provide it to its children
 */
const AuthContextProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {});

  useEffect(() => {
    if (initialState?.accessToken) {
      dispatch({ type: "SET_TOKEN", payload: initialState.accessToken });
    }
  }, []);

  // sync state with local storage
  useLocalStorage("authState", state);

  // clear token on 401
  // TODO: try refresh token (use redux middleware)
  useEffect(() => {
    const id = axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
      if (error.response?.status === 401) {
        dispatch({ type: "REMOVE_TOKEN" });
      }
      throw error;
    });
    return () => {
      axiosInstance.interceptors.response.eject(id);
    };
  }, []);

  return <AuthContext.Provider value={{ state, dispatch }} children={children} />
};

export default AuthContextProvider;
