import React from 'react'
import ReactTooltip from "react-tooltip";

export type SizeKey = 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl';

const Avatar = (
  { firstname, lastname, size, ...props }:
    React.HTMLAttributes<HTMLDivElement> & { firstname: string, lastname: string, size: SizeKey }
) => {

  const sizes = {
    'xs': 'w-4 h-4 text-xs',
    'sm': 'w-6 h-6 text-xs',
    'base': 'w-8 h-8',
    'md': 'w-10 h-10',
    'lg': 'w-12 h-12',
    'xl': 'w-16 h-16'
  }

  const initials = () => {
    return `${firstname[0]}`;
  }

  return (<div
    {...props}
    className={`${sizes[size]} rounded-full border border-white shadow bg-orange-lighter text-orange font-light flex items-center justify-center`}>
    <p data-tip={`${firstname} ${lastname}`} className="uppercase">{initials()}</p>
    <ReactTooltip clickable={true} place="top" className="custom-tooltip" effect="solid" />
  </div>);
}

export default Avatar;