import React, { useState } from 'react'

import { ReactComponent as EmptyIllustration } from "../../assets/empty-file.svg";
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as ViewMoreIcon } from '../../icons/eye.svg';
import { File } from "@ticketrz/api-types";
import FileIcon from "../file-uploader/FileIcon";
import { download } from '../../common/utils/download';
import { formatFileSize } from "../../common/utils/sizeFormater";
import Modal from "../Modal";
import { isImage } from "../../common/utils/isImage";
import { getFileUrl } from "../../common/services/project.service";



const ThreadAttachment = ({ attachments, projectId }: { attachments: Array<File & { signedPreviewUrl: string }>, projectId: number | undefined }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null)

  const handleOpenModal = async (file: File & { signedPreviewUrl: string }) => {
    if (projectId) {
      const url = await getFileUrl({
        projectId,
        fileId: file.id,
        previewMode: true
      })
      setFileUrl(url)
    }
  }

  return (
    attachments.length === 0 ?
      <div className="hidden md:flex flex-col items-center justify-center">
        <EmptyIllustration className="w-20 h-20" />
        <p className="text-sm text-gray-600">Pas de pièce jointe</p>
      </div> :
      <div className="shadow rounded hidden md:block max-h-48 xl:max-h-56 overflow-y-auto">
        {projectId ? attachments.map(attachment => <div key={attachment.id} className="group flex bg-white items-center justify-between px-4 py-4 border-b last:border-b-0 cursor-pointer">
          <div className="flex items-center w-2/3">
            <FileIcon className="text-orange-light" error={false} fileType={attachment.mimeType} />
            <p className="ml-4 font-base group-hover:text-orange-light truncate w-2/3">{attachment.name}</p>
          </div>
          <p className="group-hover:hidden text-sm text-gray-500">{formatFileSize(attachment.size)}</p>
          <div className="hidden group-hover:flex gap-3">
            {(isImage(attachment) || attachment.mimeType === "application/pdf") && (
              <>
                <div className="opacity-0 group-hover:opacity-100 transition ease-in-out duration-200 rounded-full hover:text-white text-gray-500 hover:bg-orange p-1">
                  <ViewMoreIcon className="w-4 h-4 fill-current font-light cursor-zoom-in " onClick={() => handleOpenModal(attachment)} />
                </div>
                <Modal show={!!fileUrl} handleCloseModal={() => setFileUrl(null)} canOutSideClick>
                  {fileUrl && (
                    isImage(attachment) ? (
                      <img src={fileUrl} alt="" className="flex items-center justify-center object-contain max-h-96 w-full" />
                    ) : (
                      <iframe title="pdf" src={fileUrl} style={{ width: "100%", height: "600px" }}></iframe>
                    )
                  )}
                </Modal>
              </>
            )}
            <div className="opacity-0 group-hover:opacity-100 transition ease-in-out duration-200 rounded-full hover:bg-orange p-1 hover:text-white text-gray-500">
              <DownloadIcon className=" w-4 h-4 fill-current" onClick={() => download(projectId, attachment.id, attachment.name)} />
            </div>
          </div>
        </div>
        ) : null}
      </div>
  );
};

export default ThreadAttachment;