import React, { useState, useEffect } from 'react'
import Button from "../components/forms/Button";
import ThreadInformation from "../components/thread/ThreadInformation";
import { Link, useHistory } from "react-router-dom";
import ThreadEventList from "../components/thread/ThreadEventList";
import ThreadEventForm from "../components/thread/ThreadEventForm";
import { useParams } from "react-router-dom";
import { Thread as ThreadApi, ThreadEvent, User } from "@ticketrz/api-types";
import { getThread, addMessageToThread, getThreadEvents, updateThread, getThreadUsers, updateThreadRead } from "../common/services/thread.service";
import { FormattedDate } from "react-intl";

const Thread = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();


  const [users, setUsers] = useState<User[]>([])
  const [thread, setThread] = useState<ThreadApi | null>(null)

  useEffect(() => {
    if (id) {
      getThread(id).then(response => setThread(response));
      getThreadEvents(id).then(response => setEventList(response));
      updateThreadRead(parseInt(id, 10));
      getThreadUsers(parseInt(id, 10)).then(response => setUsers(response));
    }
  }, [id]);

  const addMessage = async ({ message, fileIds }: { message: string, fileIds: number[] | undefined }) => {
    if (!thread) {
      return;
    }

    if (thread.status === 'CLOSED') {
      await updateThread(thread.id, "OPEN");
    }

    addMessageToThread(thread.id, {
      message,
      fileIds,
    }).then(response => {
      console.log("IDS", fileIds)
      if (id) {
        getThreadUsers(parseInt(id, 10)).then(response => setUsers(response));
        getThread(id).then(response => setThread(response));
        getThreadEvents(id).then(response => setEventList(response));
        updateThreadRead(parseInt(id, 10));
      }
    })
  }
  const closeThread = async () => {
    if (thread) {
      await updateThread(thread.id, "CLOSED").then(response => {
        history.push(`/threads`);
      });
    }
  }

  const [eventList, setEventList] = useState<ThreadEvent[]>([]);

  return (<div className="bg-gray-200">
    <div className="container mx-auto flex flex-col px-4 md:px-12 h-content">
      <div className="md:h-full md:flex-grow flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-2/3 flex flex-col md:pr-20 py-4">
          <div>
            <Link to="/threads" className="mb-4 hidden md:inline-block">
              <Button text="Liste des discussions" bType="secondary" icon="left-arrow" />
            </Link>
          </div>
          <div className="hidden md:flex items-start justify-between flex-wrap">
            <h1 title={thread?.subject} className="w-3/4 text-3xl break-words leading-8">{thread?.subject}</h1>
            <p className="text-gray-500 w-1/4 flex justify-end leading-8">
              <FormattedDate
                value={thread?.createdAt}
                year="numeric"
                day="numeric"
                month="long"
                hour="numeric"
                minute="numeric"
              />
            </p>
          </div>
          <ThreadEventList users={users} projectId={thread?.projectId} eventList={eventList} />
          <ThreadEventForm projectId={thread?.projectId} submit={(message, fileIds) => addMessage({ message, fileIds })} />
        </div>
        <div className="w-full mt-2 md:mt-0 md:w-1/3">
          <Link to="/threads" className="md:hidden mb-4">
            <Button text="Liste des discussions" bType="secondary" icon="left-arrow" />
          </Link>
          <div className="md:hidden flex-shrink-0 flex flex-col justify-start mt-4">
            <h1 className="text-3xl leading-none">{thread?.subject}</h1>
            <p className="text-gray-500 text-sm">
              <FormattedDate
                value={thread?.createdAt}
                year="numeric"
                day="numeric"
                month="long"
                hour="numeric"
                minute="numeric"
              />
            </p>
          </div>
          <ThreadInformation users={users} handleClosedThread={() => closeThread()} thread={thread} />
        </div>
      </div>
    </div>
  </div>)
}

export default Thread;