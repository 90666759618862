import { useEffect, useCallback } from "react";

const useOutsideClick: (ref: React.MutableRefObject<any>, callback: () => void) => void = (ref, callback) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
};

export default useOutsideClick;