export const formatFileSize = (size: number) => {
  if(size === 0) {
    return `0 octets`
  }
  size = Math.abs(size);
  const def: any = [
    [1, 'octets'],
    [1024, 'ko'],
    [1024 * 1024, 'Mo'],
    [1024 * 1024 * 1024, 'Go'],
    [1024 * 1024 * 1024 * 1024, 'To']
  ];
  for (var i = 0; i < def.length; i++) {
    if (size < def[i][0]) return (size / def[i - 1][0]).toFixed(0) + ' ' + def[i - 1][1];
  }
}