import { Editor, EditorContent } from '@tiptap/react';
import React, { useCallback, useState } from 'react';
import { ReactComponent as BoldIcon } from '../../icons/WYSIWYG/bold.svg';
import { ReactComponent as ItalicIcon } from '../../icons/WYSIWYG/italic.svg';
import { ReactComponent as StrikeIcon } from '../../icons/WYSIWYG/strike.svg';
import { ReactComponent as UnderlineIcon } from '../../icons/WYSIWYG/underline.svg';
import { ReactComponent as HighlightIcon } from '../../icons/WYSIWYG/highlight.svg';
import { ReactComponent as OrderedListIcon } from '../../icons/WYSIWYG/orderedlist.svg';
import { ReactComponent as UnorderedListIcon } from '../../icons/WYSIWYG/unorderedlist.svg';
import { ReactComponent as LinkIcon } from '../../icons/WYSIWYG/link.svg';
import FileUploader from "../../components/file-uploader/FileUploader";
import Modal from "../../components/Modal";
import Button from "../../components/forms/Button";
import { ReactComponent as ClipIcon } from '../../icons/clip.svg';


const MenuBar = ({ editor, addFile, handleOpenModal, setLinkData, isDisable }: { editor: Editor; addFile: (fileList: FileList | null) => void; handleOpenModal: () => void, setLinkData: (linkData: LinkData) => void, isDisable: boolean; }) => {

  if (!editor) {
    return null
  }

  const addLink = () => {
    const currentSelectionIsEmpty = editor.view.state.selection.empty

    if (!currentSelectionIsEmpty) {
      const currentSelection = editor.view.state.doc.textBetween(editor.view.state.selection.from, editor.view.state.selection.to, ' ');
      const { href } = editor.getAttributes("link");

      setLinkData({
        label: currentSelection,
        url: href
      })

      handleOpenModal();
      return;
    }

    handleOpenModal()
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2 py-2 rounded text-black">
        <div onClick={() => editor.chain().focus().toggleBold().run()} title="ctrl + b" className={` ${editor.isActive('bold') ? 'bg-orange ' : ''} group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <BoldIcon className={` group-hover:text-white ${editor.isActive('bold') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={() => editor.chain().focus().toggleItalic().run()} title="ctrl + i" className={` ${editor.isActive('italic') ? 'bg-orange ' : ''} group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <ItalicIcon className={` group-hover:text-white ${editor.isActive('italic') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={() => editor.chain().focus().toggleStrike().run()} title="ctrl + shift + x" className={` ${editor.isActive('strike') ? 'bg-orange ' : ''} group ${!editor.can()
          .chain()
          .focus()
          .toggleStrike()
          .run() && "pointer-events-none cursor-not-allowed"} p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <StrikeIcon className={` group-hover:text-white ${editor.isActive('strike') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={() => editor.chain().focus().toggleUnderline().run()} title="ctrl + u" className={` ${editor.isActive('underline') ? 'bg-orange ' : ''} group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <UnderlineIcon className={` group-hover:text-white ${editor.isActive('underline') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={() => editor.chain().focus().toggleHighlight({ color: "#FFDACC" }).run()} title={"ctrl + shift + h"} className={` ${editor.isActive('highlight') ? 'bg-orange ' : ''}  group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <HighlightIcon className={` w-3 h-3 group-hover:text-white ${editor.isActive('highlight') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={() => editor.chain().focus().toggleBulletList().run()} title="ctrl + shift + 8" className={` ${editor.isActive('bulletList') ? 'bg-orange ' : ''} group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <UnorderedListIcon className={` group-hover:text-white ${editor.isActive('bulletList') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={() => editor.chain().focus().toggleOrderedList().run()} title="ctrl + shift + 7" className={` ${editor.isActive('orderedList') ? 'bg-orange ' : ''} group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <OrderedListIcon className={` group-hover:text-white ${editor.isActive('orderedList') ? 'text-white' : 'text-gray-700'}`} />
        </div>
        <div onClick={addLink} className={` ${editor.isActive('link') ? 'bg-orange' : ''} group p-1 w-6 h-6 rounded cursor-pointer flex items-center transition ease-in-out duration-200 hover:bg-orange justify-center`}>
          <LinkIcon className={` group-hover:text-white ${editor.isActive('link') ? 'text-white' : 'text-gray-700'}`} />
        </div>
      </div>
      {isDisable ? (
        <div className="mt-8 cursor-not-allowed" title="Sélectionner d'abord un projet.">
          <ClipIcon className="fill-current text-gray-400 w-4 h-4 mr-2" />
        </div>
      ) : (
        <FileUploader mode="icon" handleFileAdd={(files) => addFile(files)} size={4} />
      )}
    </div>
  )
}

type TiptapProps = {
  editor: Editor;
  addFile: (fileList: FileList | null) => void;
  send: () => void;
  isDisable?: boolean;
}

type LinkData = {
  url?: string;
  label?: string;
}

export default ({ editor, addFile, send, isDisable = false }: TiptapProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [linkData, setLinkData] = useState<LinkData>({});

  const setLink = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const urlPattern = new RegExp("^https?://");

    if (linkData?.url) {
      if (urlPattern.test(linkData.url)) {
        editor?.chain().focus().extendMarkRange('link').setLink({ href: linkData.url, target: "_blank" }).insertContent(linkData.label ?? linkData.url)
          .run()

        setIsOpen(false)
        setLinkData({})
        setError("")
      } else {
        setError("Mauvais format, assurez vous que votre lien commence par http:// ou https://")
        return
      }
    } else {
      setError("Veuillez renseigner une url")
    }

  }, [editor, linkData])

  return (
    <div className="flex flex-col gap-2">
      {editor && (
        <>
          <MenuBar editor={editor} addFile={addFile} handleOpenModal={() => setIsOpen(true)} setLinkData={setLinkData} isDisable={isDisable} />
          <EditorContent editor={editor} />
          <Modal show={isOpen} handleCloseModal={() => setIsOpen(false)}>
            <p className="text-center text-xl font-semibold mb-4">Insérer un lien</p>
            <form className="flex flex-col gap-4 justify-center items-center" onSubmit={(e) => setLink(e)}>
              <div className="flex flex-col gap-2 w-2/3">
                <label htmlFor="">L'url du site web</label>
                <input type="text" value={linkData?.url || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLinkData({ ...linkData, url: e.target.value })} placeholder="https://google.fr" required className="placeholder-gray-500 w-full font-bold block bg-gray-300 px-2 py-2 text-base rounded-lg border border-transparent focus:bg-white focus:border-gray-400" />
                {error && (<p className="text-red-700 text-sm">{error}</p>)}
              </div>
              <div className="flex flex-col gap-2 w-2/3">
                <label htmlFor="" className="flex items-center gap-2">Le nom apparent du site web <small className="text-gray-500">optionnel</small></label>
                <input type="text" value={linkData?.label || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLinkData({ ...linkData, label: e.target.value })} placeholder="Google" className="placeholder-gray-500 w-full font-bold block bg-gray-300 px-2 py-2 text-base rounded-lg border border-transparent focus:bg-white focus:border-gray-400" />
              </div>
              <Button bType="primary" text="Crée mon lien" type="submit" />
            </form>
          </Modal>
        </>
      )}
    </div>
  )
}
