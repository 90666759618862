import React, { useState, useEffect, useContext } from 'react'
import Search from "../components/Search";
import Button from "../components/forms/Button";
import ProjectItem from "../components/project/ProjectItem";
import Modal from "../components/Modal";
import NewUserForm from "../components/project/NewUserForm";
import { Project, PaginatedList } from '@ticketrz/api-types';
import useDebounce from "../common/hooks/useDebounce";
import Loader from "../components/Loader";
import { ReactComponent as CrossIcon } from '../icons/cross.svg'
import { fetchProjects } from "../common/services/project.service";
import { SelectValue } from "../components/forms/InputSelect";
import { AuthContext } from '../components/auth/AuthContextProvider';
import { ReactComponent as EmptyIllustration } from "../assets/empty-thread-list.svg";
import { decodeJwt } from "../common/auth/auth.service";
import Pagination from "../components/Pagination";

const Projects = () => {
  const ITEM_BY_PAGE = 15;
  const { state } = useContext(AuthContext);
  const userInfo = state.accessToken ? decodeJwt(state.accessToken) : null;
  const [showModal, setshowModal] = useState(false)
  const [page, setPage] = useState(1)
  const [projects, setProjects] = useState<PaginatedList<Project>>({
    count: 0,
    total: 0,
    list: [],
  });

  const [currentProject, setCurrentProject] = useState<SelectValue | null>(null)

  const [success, setSuccess] = useState(false)

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const debouncedQuery = useDebounce(searchQuery, 200);

  const openModelWithCurrentProject = (projetId: number, projectName: string) => {
    setCurrentProject({
      label: projectName,
      value: projetId,
    });
    setshowModal(true);
  }

  useEffect(() => {
    let cancelled = false;
    setIsLoading(true)
    fetchProjects({
      search: debouncedQuery || undefined,
      limit: ITEM_BY_PAGE,
      offset: (page - 1) * ITEM_BY_PAGE,
    })
      .then(response => setProjects(response))
      .finally(() => !cancelled && setIsLoading(false));
    return () => {
      cancelled = true;
    };
  }, [debouncedQuery, page]);

  return (<div className="container mx-auto p-8">
    <div className="flex flex-col lg:flex-row items-center justify-between mb-12">
      <Search handleChange={(e) => setSearchQuery(e.target.value)} className="mb-4 lg:mb-0" />
      {
        userInfo?.type === 'agent'
          ? <Button
            icon="plus"
            text="Ajouter collaborateur client"
            bType="primary"
            onClick={() => setshowModal(true)}
            className="mr-4 mb-2 md:mb-0"
          />
          : null
      }
    </div>
    {success ? <div className="bg-green-200 rounded px-4 py-2 flex justify-between text-green-700">
      <p>Collaborateur client ajouté avec succès !</p>
      <CrossIcon className="fill-current cursor-pointer" onClick={() => setSuccess(false)} />
    </div> : null}
    {
      projects.list.length === 0 && !isLoading ?
        <div className="flex flex-col items-center justify-center md:mt-64">
          <p className="font-bold">Pas de projet</p>
          <EmptyIllustration className="w-64 h-64" />
        </div>
        : isLoading ?
          <div className="flex flex-col items-center justify-center md:mt-64">
            <Loader />
          </div> :
          <div>
            <div className="flex flex-wrap -mx-4">
              {projects.list.map(project =>
                <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-6" key={project.id}>
                  <ProjectItem userInfo={userInfo} handleAddUser={(projetId, projectName) => openModelWithCurrentProject(projetId, projectName)} project={project} />
                </div>)}
            </div>
            <div className="flex justify-end">
              <Pagination handlePageChange={(page) => setPage(page)} count={projects.count} limit={ITEM_BY_PAGE} total={projects.total} offset={projects.offset} />
            </div>
          </div>
    }

    <Modal show={showModal} handleCloseModal={() => setshowModal(false)} >
      <NewUserForm currentProject={currentProject} formSubmitted={() => setSuccess(true)} handleBackEvent={() => setshowModal(false)} />
    </Modal>
  </div>)
}

export default Projects;