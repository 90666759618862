import React from 'react';
import { ReactComponent as ClipIcon } from '../../icons/clip.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { clsx } from 'clsx';

type Size = 4 | 6

const FileUploader = ({ mode, handleFileAdd, size = 6 }: { mode: 'icon' | 'cta', handleFileAdd: (files: FileList | null) => void, size?: Size }) => {

  const clipIcon = clsx({
    "w-4 h-4 md:w-6 md:h-6": size === 6,
    "w-4 h-4": size === 4
  }, "fill-current text-gray-700 mr-2")

  return (<div>
    {mode === 'icon' ? <label htmlFor="file" className="cursor-pointer">
      <ClipIcon className={clipIcon} />
    </label>
      : <label htmlFor="file" className="cursor-pointer bg-gray-100 border border-gray-300 w-12 h-12 mb-6 rounded flex items-center justify-center">
        <PlusIcon className="fill-current w-8 h-8 text-gray-600" />
      </label>
    }
    <input type="file" id="file" multiple name="file" value={""} className="hidden" onChange={(event) => handleFileAdd(event.target.files)} />
  </div>)
}

export default FileUploader;