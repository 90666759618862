import React from 'react';
import { ReactComponent as ArrowUp } from "../../icons/bar-arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../icons/bar-arrow-down.svg";
import useBoundStore from "../../store";

const ThreadSort = () => {

  const { sort: currentSort, setSort } = useBoundStore();
  console.log(currentSort.sort)
  return (<div className="w-72">
    <div className="rounded-lg flex flex-col items-start">
      <p className="uppercase text-xs font-bold w-full tracking-widest py-2 px-4 border-b border-gray-300 bg-gray-200 rounded-t-lg">Trier par</p>
      <button
        className={`${currentSort.sort === "createdAt" ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2 whitespace-no-wrap`}
        onClick={() => setSort({ sort: { ...currentSort, sort: "createdAt" } })}
      >Date d'ouverture de la discussion</button>
      <button
        className={`${currentSort.sort === 'threadEvents' ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2 whitespace-no-wrap`}
        onClick={() => setSort({ sort: { ...currentSort, sort: "threadEvents" } })}
      >Date du dernier message</button>
      <button
        className={`${currentSort.sort === 'subject' ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2 whitespace-no-wrap`}
        onClick={() => setSort({ sort: { ...currentSort, sort: "subject" } })}
      >Ordre alphabétique du sujet</button>

      {/* <p className="uppercase text-xs font-bold w-full tracking-widest py-2 px-4 border-b border-gray-300 bg-gray-200">Trier par ordre alphabétique</p> */}


      <p className="uppercase text-xs font-bold w-full tracking-widest py-2 px-4 border-b border-gray-300 bg-gray-200">Sens de tri</p>
      <button
        className={`${currentSort.order === 'ASC' ? 'text-orange' : null} text-left w-full flex items-center gap-2 whitespace-no-wrap hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setSort({ sort: { ...currentSort, order: "ASC" } })}
      ><ArrowUp className="w-5" /> Tri croissant</button>
      <button
        className={`${currentSort.order === 'DESC' ? 'text-orange' : null} flex items-center gap-2 text-left w-full whitespace-no-wrap hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setSort({ sort: { ...currentSort, order: "DESC" } })}
      ><ArrowDown className="w-5" /> Tri décroissant</button>
    </div>
  </div>);
}

export default ThreadSort;