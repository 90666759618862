import React, { useMemo } from "react";
import FilterBadge from "./FilterBadge";

const FilterStatus = ({ status }: { status: string | undefined }) => {
  const statusText = useMemo(() => {
    switch (status) {
      case "OPEN":
        return "Ouvert"

      case "PENDING":
        return "En attente"

      case "CLOSED":
        return "Fermé"

      default:
        break;
    }
  }, [status])
  return (
    <>
      {statusText && (
        <FilterBadge text={statusText} />
      )}
    </>
  )

}

export default FilterStatus