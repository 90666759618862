import React, { useState, useEffect } from 'react';
import Search from "../components/Search";
import Button from "../components/forms/Button";
import ThreadCard from "../components/thread/ThreadCard";
import Modal from "../components/Modal";
import ThreadForm from "../components/thread/ThreadForm";
import { PaginatedList, Thread } from "@ticketrz/api-types";
import useDebounce from "../common/hooks/useDebounce";
import { fetchUserThread } from "../common/services/thread.service";
import { ReactComponent as EmptyIllustration } from "../assets/empty-thread-list.svg";
import Loader from "../components/Loader";
import SubMenu from "../components/forms/SubMenu";
import ThreadFilter from "../components/thread/ThreadFilter";
import Pagination from "../components/Pagination";
import ThreadSort from "../components/thread/ThreadSort";
import { ReactComponent as Grid } from '../icons/grid.svg';
import { ReactComponent as List } from '../icons/list.svg';
import useBoundStore from "../store";
import { isGrid } from "../common/utils/isGrid";
import ThreadFilters from "../components/thread/ThreadFIlters";

const Threads = () => {
  const ITEM_BY_PAGE = 9;
  const [showMenu, setShowMenu] = useState(false)
  const [showSortMenu, setShowSortMenu] = useState(false)
  const [page, setPage] = useState(1)
  const [showModal, setshowModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true)
  const { filter, sort, displaying, setDisplaying } = useBoundStore()

  const [threads, setThreads] = useState<PaginatedList<Thread>>({
    count: 0,
    list: [],
    total: 0,
  })
  const debouncedQuery = useDebounce(searchQuery, 200);

  const handleToggleMode = (): void => {
    if (isGrid(displaying)) {
      setDisplaying({ displaying: "list" })
    } else {
      setDisplaying({ displaying: "grid" })
    }
  }

  useEffect(() => {
    let cancelled = false;
    setIsLoading(true);
    fetchUserThread({
      search: debouncedQuery || undefined,
      limit: ITEM_BY_PAGE,
      offset: (page - 1) * ITEM_BY_PAGE,
      ...filter,
      ...sort,
    })
      .then(response => setThreads(response))
      .finally(() => !cancelled && setIsLoading(false));
    return () => {
      cancelled = true;
    };
  }, [debouncedQuery, page, filter, sort]);

  useEffect(() => {
    setPage(1);
  }, [filter, sort])



  return (<div className="container mx-auto p-8">
    <div className="flex flex-col lg:flex-row items-center justify-between mb-5">
      <Search handleChange={(e) => setSearchQuery(e.target.value)} className="mb-4 lg:mb-0" />
      <div className="flex flex-grow-0 flex-col gap-3 md:gap-0 w-full md:w-auto md:flex-row md:items-center items-start">
        <Button icon="plus" text="Nouvelle discussion" bType="primary" className="mr-4 mb-2 md:mb-0 whitespace-no-wrap" onClick={() => setshowModal(!showModal)} />
        {/* TODO: change for a select */}
        <div className="relative mr-4">
          <Button icon="chevron-down" className="outline-none whitespace-no-wrap" onClick={() => setShowMenu(true)} text="Filtrer par" bType="secondary" />
          {showMenu ? <SubMenu hideMenu={() => setShowMenu(false)}>
            <ThreadFilter />
          </SubMenu> : null}
        </div>
        <div className="relative mr-4">
          <Button icon="chevron-down" className="outline-none whitespace-no-wrap" onClick={() => setShowSortMenu(true)} text="Trier par" bType="secondary" />
          {showSortMenu ? <SubMenu hideMenu={() => setShowSortMenu(false)}>
            <ThreadSort />
          </SubMenu> : null}
        </div>

        <div className="flex items-center">
          <Grid className={`transition ease-in-out duration-300 ${isGrid(displaying) ? "text-orange" : "text-gray-500"}`} />
          <div className="cursor-pointer" onClick={() => handleToggleMode()}>
            <div className={`w-12 p-1 h-7 flex items-center ${isGrid(displaying) ? "bg-gray-300 " : "bg-orange"} rounded-full mx-3`}>
              <div className={`bg-white w-5 h-5 rounded-full shadow-md transition ease-in-out duration-300 transform ${isGrid(displaying) ? "translate-x-7" : "translate-x-full"}`}></div>
            </div>
          </div>
          <List className={`transition ease-in-out duration-300 ${!isGrid(displaying) ? "text-orange" : "text-gray-500"}`} />
        </div>

      </div>
    </div>
    {(filter && sort) && (
      <ThreadFilters />
    )}
    {
      threads.count === 0 && !isLoading ?
        <div className="flex flex-col items-center justify-center md:mt-64">
          <p className="font-bold">Pas de discussion</p>
          <EmptyIllustration className="w-64 h-64" />
          <Button icon="plus" text="Nouvelle discussion" bType="primary" className="mb-2 md:mb-0" onClick={() => setshowModal(!showModal)} />
        </div>
        : isLoading ?
          <div className="flex flex-col items-center justify-center md:mt-64">
            <Loader />
          </div>
          :
          <div>
            <div className={`${isGrid(displaying) ? "flex flex-wrap -mx-4" : "grid grid-cols-1 w-full"}`}>
              {
                threads.list.map(thread => <div key={thread.id} className={`w-full ${isGrid(displaying) ? "md:w-1/2 lg:w-1/3 px-4  mb-8" : "mb-2"}`}><ThreadCard thread={thread} /></div>)
              }
            </div>
            <div className={`flex justify-end ${!isGrid(displaying) && "mt-8"}`}>
              <Pagination handlePageChange={(page) => setPage(page)} count={threads.count} limit={ITEM_BY_PAGE} total={threads.total} offset={threads.offset} />
            </div>
          </div>
    }

    <Modal show={showModal} handleCloseModal={() => setshowModal(false)} canOutSideClick={false}>
      <ThreadForm handleBackEvent={() => setshowModal(false)} />
    </Modal>
  </div >);
}

export default Threads;