import React from 'react'
// import { ReactComponent as UsersIcon } from "../../icons/multi-user.svg";
// import { ReactComponent as BubblesIcon } from "../../icons/multi-bubble.svg";
import { ReactComponent as AddUserIcon } from "../../icons/add-user.svg";
import { Project, UserTokenPayload } from '@ticketrz/api-types';

const ProjectItem = (
  {
    project,
    handleAddUser,
    userInfo
  }: {
    userInfo: UserTokenPayload | null,
    project: Project,
    handleAddUser: (id: number, name: string) => void
  }) => {
  return (<div className="px-4 py-4 shadow-lg rounded bg-white cursor-pointer">
    <div className="flex justify-between">
      <div className="w-5/6">
        <h3 title={project.client} className="text-xs text-orange-light truncate">{project.client}</h3>
        <h4 title={project.name} className="text-xl pb-2 pt-1 leading-none truncate">{project.name}</h4>
        <p className="text-gray-500 text-xs tracking-wider">{project.reference}</p>
      </div>
      {userInfo?.type === 'agent'
        ? <AddUserIcon
          className="fill-current w-1/6 flex-shrink-0 text-orange-light hover:text-orange cursor-pointer"
          onClick={() => handleAddUser(project.id, project.name)}
        />
        : null
      }
    </div>
    {/* // TODO: Add project summary endpoint */}
    {/* <div className="flex mt-4">
      <div className="flex items-center mr-4">
        <UsersIcon className="fill-current w-5 h-5 text-gray-500" />
        <p className="ml-1 font-bold">3</p>
      </div>
      <div className="flex items-center">
        <BubblesIcon className="fill-current w-5 h-5 text-gray-500" />
        <p className="ml-1 font-bold">5</p>
      </div>
    </div> */}
  </div>);
};

export default ProjectItem;