import { GetProjectsDto, PaginatedList, Project } from "@ticketrz/api-types"
import axiosInstance from "../utils/axios";

export const fetchProjects = async (
  {
    search,
    limit,
    offset,
  }: {
    limit: number;
    search?: string;
    offset: number;
  },
) => {
  const params: GetProjectsDto = {
    search: search && `%${search}%`,
    limit,
    offset,
  };
  const response = await axiosInstance.get<PaginatedList<Project>>('/projects', { params });
  return response.data;
}

export const uploadFileToProject = async (
  { id, file, attachment }: { id: number, file: File, attachment: boolean },
) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axiosInstance.post<number>(
    `/projects/${id}/files?attachment=${attachment}`,
    formData,
  );
  return response.data;
}

export const getFileUrl = (
  { projectId, fileId, previewMode }: { projectId: number, fileId: number, previewMode?: boolean },
) => axiosInstance.get<string>(`/projects/${projectId}/files/${fileId}/url`, { params: { previewMode } })
  .then(response => response.data);
