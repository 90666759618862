import React, { useEffect, useContext, useState, useMemo } from 'react';
import Avatar from "../Avatar";
import { ReactComponent as BubbleIcon } from "../../icons/bubble.svg";
import { ReactComponent as ClipIcon } from "../../icons/clip.svg";
import { Link } from "react-router-dom";
import { Thread } from "@ticketrz/api-types";
import { getThreadSummary } from "../../common/services/thread.service";
import { AuthContext } from "../auth/AuthContextProvider";
import { GetThreadSummaryResponse } from "@ticketrz/api-types/dist/thread/types";
import { FormattedDate } from "react-intl";
import { decodeJwt } from "../../common/auth/auth.service";
import useBoundStore from "../../store";
import { isGrid } from "../../common/utils/isGrid";

const ThreadCard = ({ thread }: { thread: Thread }) => {
  const { state } = useContext(AuthContext);
  const userInfo = state.accessToken ? decodeJwt(state.accessToken) : null;
  const dotStyle: React.CSSProperties = {
    right: '-8px',
    top: '-2px'
  }

  const [summary, setSummary] = useState<GetThreadSummaryResponse | null>(null)
  const { displaying } = useBoundStore();

  useEffect(() => {
    if (thread) {
      getThreadSummary(thread.id).then(response => setSummary(response))
    }
  }, [thread])

  const lastMessage: string | undefined | null = useMemo(() => {
    if (summary?.lastMessage?.message) {
      const node = new DOMParser().parseFromString(summary.lastMessage.message, 'text/html').body

      if (node.firstChild) {
        return node.firstChild.textContent
      }

      return node.firstElementChild?.textContent
    }
  }, [summary])

  const unreadThreads = () => {
    const userThreadReads = summary?.users.filter(u => u.id === userInfo?.id)[0];
    if (!userThreadReads) {
      return false;
    }
    const threadReads = userThreadReads.threadReads;
    if (!threadReads) {
      return false;
    }
    if (!summary?.lastMessage.createdAt) {
      return false;
    }
    return new Date(threadReads[0]?.date).getTime() < new Date(summary?.lastMessage.createdAt).getTime();
  };

  const lastUser = summary?.users.find(u => u.id === summary?.lastMessage?.userId);

  return summary && (
    <>
      {isGrid(displaying) ? (
        <Link to={`/threads/${thread.id}`} className={`${unreadThreads() ? 'border-orange' : 'border-gray-500'} block border-l-4 rounded shadow-lg px-5 py-4 cursor-pointer`}>
          <div className="flex justify-end">
            {thread.status === "CLOSED" ?
              <div className="bg-red-200 rounded text-sm md:text-base px-1 flex flex-col justify-center">
                <p className="text-red-700 text-xs font-bold">Fermé</p>
              </div> : thread.status === "PENDING" ? (
                <div className="bg-yellow-400 rounded text-sm md:text-base px-1 flex flex-col justify-center">
                  <p className="text-yellow-700 text-xs font-bold">En attente de réponse</p>
                </div>
              ) : null}
            <div data-tip={`${thread.project?.client}`} className="uppercase text-xs border flex flex-col justify-center border-gray-500 text-gray-500 font-bold px-1 rounded ml-2">
              <p>{thread.project?.name}</p>
            </div>
          </div>
          <h4 className="text-xl md:text-2xl font-base truncate">{thread.subject}</h4>
          {lastMessage && (
            <p className="text-gray-500  mt-3 mb-1 text-sm truncate">{lastMessage}</p>
          )}
          <div className="flex items-center">
            {lastUser ? <Avatar size="sm" firstname={lastUser.firstname} lastname={lastUser.lastname} className="w-4 h-4" /> : null}
            <p className="text-gray-500 ml-2 text-xs">
              <FormattedDate
                value={summary?.lastMessage?.createdAt}
                year="numeric"
                day="numeric"
                month="long"
                hour="numeric"
                minute="numeric"
              />
            </p>
          </div>
          <hr className="mt-8 mb-4 border-gray-300" />
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center mr-4">
                <BubbleIcon className="fill-current w-4 h-4 text-gray-500 mr-2" />
                <p className="font-bold relative">
                  {unreadThreads() ? <span style={dotStyle} className="bg-orange w-2 h-2 rounded-full absolute">&nbsp;</span> : null}
                  <span>{summary?.messageCount}</span>
                </p>
              </div>
              <div className="flex items-center">
                <ClipIcon className="fill-current w-4 h-4 text-gray-500 mr-2" />
                <p className="font-bold mr-2">{summary?.fileCount}</p>
              </div>
            </div>
            {summary ? <div className="flex items-center">
              {summary.users.slice(0, 3).map(u => <div key={u.id} className="-ml-2">
                <Avatar size="sm" firstname={u.firstname} lastname={u.lastname} />
              </div>)}
              {summary.userCount > 3 ? <p className="text-gray-500 text-sm ml-1">+{summary.userCount - 3}</p> : null}
            </div> : null}
          </div>
        </Link>
      ) : (
        <Link to={`/threads/${thread.id}`} className={`${unreadThreads() ? 'border-orange' : 'border-gray-400'} relative block border-l-4 rounded-r border p-5 cursor-pointer`}>
          <div className="flex items-center gap-4">
            <div className="w-1/5 flex">
              {thread.status === "CLOSED" ?
                <div className="bg-red-200 absolute top-0 left-0 rounded-br text-sm md:text-base px-1 flex flex-col justify-center">
                  <p className="text-red-700 text-xs font-bold">Fermé</p>
                </div> : thread.status === "PENDING" ? (
                  <div className="bg-yellow-400 absolute top-0 left-0 rounded-br text-sm md:text-base px-1 flex flex-col justify-center">
                    <p className="text-yellow-700 text-xs font-bold whitespace-no-wrap">En attente de réponse</p>
                  </div>
                ) : null}
              <div data-tip={`${thread.project?.client}`} className="w-fit uppercase text-xs border flex flex-col justify-center border-gray-500 text-gray-500 font-bold px-1 rounded ml-2">
                <p className="whitespace-no-wrap">{thread.project?.name}</p>
              </div>
            </div>
            <h4 className="text-md md:text-lg font-base w-2/5 truncate" title={thread.subject}>{thread.subject}</h4>
            <div className="w-1/5">
              {lastMessage && (
                <p className="text-gray-500 text-sm truncate">{lastMessage}</p>
              )}
              <div className="flex items-center gap-2">
                {lastUser ? <Avatar size="xs" firstname={lastUser.firstname} lastname={lastUser.lastname} className="w-4 h-4" /> : null}
                <p className="text-gray-500 ml-2 text-xs">
                  <FormattedDate
                    value={summary?.lastMessage?.createdAt}
                    year="numeric"
                    day="numeric"
                    month="long"
                    hour="numeric"
                    minute="numeric"
                  />
                </p>
              </div>
            </div>
            <div className=" w-1/5 flex md:flex-row flex-col items-center justify-end">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  <BubbleIcon className="fill-current w-4 h-4 text-gray-500 mr-2" />
                  <p className="font-bold relative">
                    {unreadThreads() ? <span style={dotStyle} className="bg-orange w-2 h-2 rounded-full absolute">&nbsp;</span> : null}
                    <span>{summary?.messageCount}</span>
                  </p>
                </div>
                <div className="flex items-center mr-4">
                  <ClipIcon className="fill-current w-4 h-4 text-gray-500 mr-2" />
                  <p className="font-bold mr-2">{summary?.fileCount}</p>
                </div>
              </div>
              {summary ? <div className="flex items-center">
                {summary.users.slice(0, 3).map(u => <div key={u.id} className="-ml-2">
                  <Avatar size="sm" firstname={u.firstname} lastname={u.lastname} />
                </div>)}
                {summary.userCount > 3 ? <p className="text-gray-500 text-sm ml-1">+{summary.userCount - 3}</p> : null}
              </div> : null}
            </div>
          </div>
        </Link>
      )}
    </>
  )
}


export default ThreadCard;
