import React, { useState } from 'react'
import Button from "../forms/Button";
import InputText from "../forms/InputText";
import UserSelect from "./UserSelect";
import ProjectSelect from "./ProjectSelect";
import { SelectValue } from '../forms/InputSelect';
import { attachUserToProject, createUser } from "../../common/services/user.service";

interface FormModel {
  projectId: SelectValue | null;
  userId: SelectValue | null;
  email: string;
  phone: string;
  firstname: string;
  lastname: string;
  error: string | null;
  success: boolean;
}

const NewUserForm = (
  {
    currentProject,
    handleBackEvent,
    formSubmitted
  }: {
    currentProject: SelectValue | null,
    handleBackEvent: () => void,
    formSubmitted: () => void
  }) => {
  const [model, setModel] = useState<FormModel>({
    error: null,
    success: false,
    projectId: currentProject,
    userId: null,
    email: '',
    phone: '',
    firstname: '',
    lastname: '',

  });

  const handleSelectChange = (e: SelectValue, key: 'projectId' | 'userId') => {
    setModel({ ...model, [key]: e });
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setModel({ ...model, error: null })
    if (!model.projectId) {
      setModel({ ...model, error: 'Vous devez selectionner un projet' });
      return;
    }
    if (!model.firstname || !model.lastname || !model.email) {
      if (model.userId && model.userId.value !== 0) {
        await attachUserToProject(model.userId.value, model.projectId.value);
        clearForm();
        formSubmitted();
        handleBackEvent();
        return;
      } else {
        setModel({
          ...model,
          error: 'Vous devers définir un email, un nom et un prénom pour créer un utilisateur ou selectionner un utilisateur existant'
        });
        return;
      }
    } else {
      const response = await createUser({ firstname: model.firstname, lastname: model.lastname, email: model.email })
        .catch(err => {
          if (err.response?.data?.message) {
            if (Array.isArray(err.response?.data?.message)) {
              setModel({ ...model, error: 'Une erreur est survenue.' });
            } else {
              setModel({ ...model, error: err.response?.data?.message });
            }
          }
        })
      if (response) {
        await attachUserToProject(response.data, model.projectId.value).catch(_ => {
          setModel({ ...model, error: 'Une erreur est survenue.' });
        })
        clearForm();
        formSubmitted();
        handleBackEvent();
        return;
      }
    }
  }

  const clearForm = () => {
    setModel({
      error: null,
      success: model.success,
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      projectId: null,
      userId: null,
    });
  }

  return (<div>
    <h4 className="text-xl md:text-4xl font mb-8">Nouveau collaborateur client</h4>
    <form onSubmit={(e) => submit(e)}>
      <ProjectSelect value={model.projectId} handleChange={(e) => handleSelectChange(e as SelectValue, 'projectId')} />
      <UserSelect value={model.userId} handleChange={(e) => handleSelectChange(e as SelectValue, 'userId')} />
      {model.userId?.value === 0 ?
        <div className="flex flex-col md:flex-row flex-wrap -mx-2 pt-4">
          <p className="w-full uppercase font-bold text-xs tracking-widest mx-2 mb-4 border-b border-gray-300">Nouveau collaborateur client</p>
          <div className="w-full md:w-1/2 px-2">
            <InputText value={model.email} type="email" onChange={(e) => setModel({ ...model, email: e.target.value })} label="Email" placeholder="example@email.com" />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <InputText value={model.phone} type="text" onChange={(e) => setModel({ ...model, phone: e.target.value })} label="Télephone" optional placeholder="12 34 56 78 90" />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <InputText value={model.lastname} type="text" onChange={(e) => setModel({ ...model, lastname: e.target.value })} label="Nom" placeholder="Dupond" />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <InputText value={model.firstname} type="text" onChange={(e) => setModel({ ...model, firstname: e.target.value })} label="Prénom" placeholder="Jean" />
          </div>
        </div>
        : null}
      {
        model.error ?
          <div className="bg-red-200 rounded px-4 py-2 text-red-700"><p >{model.error}</p></div>
          : null
      }
      <div className="flex items-center justify-between mb-12 md:mb-0 mt-12">
        <Button text="Annuler" bType="default" onClick={() => handleBackEvent()} />
        <Button text="Ajouter" bType="primary" type="submit" />
      </div>
    </form>
  </div>);
};

export default NewUserForm;