import React, { useContext } from "react";
import { AuthContext } from "./AuthContextProvider";
// https://login.microsoftonline.com/b0c53d9a-0b6a-4af1-bc64-ba84dd1d4b6e/oauth2/v2.0/logout

// clear api refresh token ?

const LogoutButton = () => {
  const { dispatch } = useContext(AuthContext);
  const handleClick = () => {
    dispatch({ type: "REMOVE_TOKEN" });
  }

  return <button className="hover:bg-orange-lighter hover:text-orange rounded-lg px-4 py-3" onClick={handleClick}>Se déconnecter</button>;
};

export default LogoutButton;
