import React, { useState } from 'react'
import Avatar from "../Avatar";
import SubMenu from "../forms/SubMenu";
import LogoutButton from "../auth/LogoutButton";

const AccountMenu = ({ className, firstname, lastname }: { className: string | undefined, firstname: string, lastname: string }) => {

  const [showMenu, setShowMenu] = useState(false)

  return (<div className={className}>
    <button className="focus:outline-none flex items-center" onClick={() => setShowMenu(true)}>
      <Avatar size="base" firstname={firstname} lastname={lastname} />
      <p className="font-bold ml-2">{firstname} {lastname}</p>
    </button>
    {showMenu ?
      <SubMenu hideMenu={() => setShowMenu(false)}>
        <LogoutButton />
      </SubMenu>
      : null
    }
  </div>)
}

export default AccountMenu;