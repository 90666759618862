import React from 'react'

import { ReactComponent as LeftChevronIcon } from "../icons/chevron-left.svg";
import { ReactComponent as RightChevronIcon } from "../icons/chevron-right.svg";

const Pagination = ({
  count,
  total,
  offset,
  limit,
  handlePageChange
}: {
  count: number;
  total: number;
  offset: number | undefined;
  limit: number;
  handlePageChange: (page: number) => void;
}) => {
  const numberOfPage = Math.ceil(total / limit);
  const pages = numberOfPage > 1 ? Array.from(Array(numberOfPage), (x, index) => index + 1) : null;
  const currentPage = offset ? Math.ceil(offset / limit) + 1 : 1;


  return (pages ? <div className="flex items-center">
    {currentPage !== 1 && (
      <button className="text-orange rounded-full w-6 h-6 mr-2 flex items-center justify-center" onClick={() => handlePageChange(currentPage - 1)}>
        <LeftChevronIcon className="fill-current" />
      </button>
    )}
    <div className="flex items-center">
      {pages.map(p => <button
        key={p}
        onClick={() => handlePageChange(p)}
        className={`${currentPage === p ? 'bg-orange text-white' : 'text-orange'} border-orange border rounded-full w-6 h-6 font-bold flex items-center justify-center mr-2 last:mr-0`}
      >
        {p}
      </button>)}
    </div>
    {currentPage < numberOfPage && (
      <button className="text-orange rounded-full w-6 h-6 ml-2 flex items-center justify-center" onClick={() => handlePageChange(currentPage + 1)}>
        <RightChevronIcon className="fill-current" />
      </button>
    )}
  </div> : null)
}

export default Pagination;