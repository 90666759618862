import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { useEditor } from '@tiptap/react'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import Bold from '@tiptap/extension-bold'
import Strike from '@tiptap/extension-strike'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from "@tiptap/extension-list-item"
import Italic from '@tiptap/extension-italic'
import Placeholder from '@tiptap/extension-placeholder'
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Paragraph from "@tiptap/extension-paragraph"
import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import ts from 'highlight.js/lib/languages/typescript'
import php from 'highlight.js/lib/languages/php'
import html from 'highlight.js/lib/languages/xml'
import { lowlight } from 'lowlight'

lowlight.registerLanguage('html', html)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)
lowlight.registerLanguage('php', php)

const useGetEditor = ({ placeholder, handleChange }: { placeholder: string; handleChange: (value: string) => void }) => {
  const editor = useEditor({
    editorProps: {
      attributes: {
        class: 'prose prose-sm min-h-24 sm:prose lg:prose-lg  xl:prose-2xl mx-auto max-h-64 overflow-y-auto focus:outline-none border p-2 bg-gray-200 focus:bg-transparent rounded',
      },
    },
    onUpdate({ editor }) {
      handleChange(editor.getHTML());
    },
    extensions: [
      Document,
      Text,
      Placeholder.configure({
        placeholder,
      }),
      Paragraph,
      ListItem,
      BulletList.configure({
        keepMarks: true,
        keepAttributes: false,
        HTMLAttributes: {
          class: "list-disc pl-4"
        },
      }),
      OrderedList.configure({
        itemTypeName: 'listItem',
        HTMLAttributes: {
          class: "list-decimal pl-4"
        },
        keepMarks: true,
        keepAttributes: false,
      }),
      Bold,
      Strike,
      Underline,
      Italic,
      Highlight.configure({
        multicolor: true
      }),
      CodeBlockLowlight.configure({
        lowlight,
        defaultLanguage: "js",
        HTMLAttributes: {
          class: "bg-black rounded text-white py-2 px-4"
        }
      }),
      Link.configure({
        HTMLAttributes: {
          class: "underline text-orange-light cursor-pointer"
        },
      }),
    ],
    autofocus: true,
  })

  return editor
}

export default useGetEditor;