import React, { useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from "../../icons/cross.svg";
import Loader from "../Loader";
import { uploadFileToProject } from "../../common/services/project.service";
import ReactTooltip from "react-tooltip";
import Modal from "../../components/Modal";
import FileIcon from "./FileIcon";

export interface UploadedFileProp {
  file: File;
  localId: string;
  projectId: number | undefined;
  handleFileRemoved: (localId: string) => void;
  handleFileCompleted: (localId: string, databaseId: number) => void;
}

const UploadedFile = ({ file, handleFileRemoved, handleFileCompleted, projectId, localId }: UploadedFileProp) => {
  const [complete, setComplete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    let cancelled = false;
    setError(false);
    if (projectId) {
      uploadFileToProject({ id: projectId, file, attachment: true })
        .then(id => handleFileCompleted(localId, id))
        .catch(_ => !cancelled && setError(true))
        .finally(() => !cancelled && setComplete(true));
    }
    return () => {
      cancelled = true;
    };
  }, [file, projectId, handleFileCompleted, localId])

  const deleteStyle: React.CSSProperties = {
    top: -8,
    right: -8,
  }

  return (<div className="flex flex-col items-center mr-4">
    <div className="bg-gray-100 border border-gray-300 w-12 h-12 rounded flex items-center justify-center relative" data-tip="Cliquez sur l'image pour l'agrandir" onClick={() => setIsOpen(true)} >
      {complete
        ? <div style={deleteStyle} className="group bg-white border border-gray-700 hover:bg-red-300 hover:border-red-800 rounded-full absolute cursor-pointer">
          <DeleteIcon className="fill-current text-gray-700 group-hover:text-red-800 w-4 h-4" onClick={() => handleFileRemoved(localId)} />
        </div>
        : <Loader className="h-12 w-12 absolute" />
      }

      {file.type.includes("image/") ? (
        <img src={URL.createObjectURL(file)} className="cursor-zoom-in object-cover h-full" alt="Preview" />
      ) : (
        <FileIcon className="text-orange-light cursor-zoom-in" error={error} fileType={file.type} />
      )}

    </div>
    <p className="truncate text-gray-600 w-12">{file.name}</p>
    <Modal show={isOpen} handleCloseModal={() => setIsOpen(false)} canOutSideClick>
      {file.type.includes("image/") ? (
        <img src={URL.createObjectURL(file)} alt="" className="flex items-center justify-center object-contain max-h-96 w-full" />
      ) : (
        <iframe title="pdf" src={URL.createObjectURL(file)} style={{ width: "100%", height: "600px" }}></iframe>
      )}
    </Modal>
    <ReactTooltip place="top" className="custom-tooltip" effect="solid" />
  </div>)
}

export default React.memo(UploadedFile);