import React, { useRef, ReactChildren, ReactChild } from 'react'
import useOutsideClick from "../../common/hooks/useOutsideClick";

const SubMenu = ({ hideMenu, children }: { children: ReactChild | ReactChildren, hideMenu: () => void }) => {

  const ref = useRef<HTMLDivElement>(null);

  const style: React.CSSProperties = {
    top: '40px',
  }

  useOutsideClick(ref, () => {
    hideMenu()
  });

  return (<div style={style} ref={ref} className="absolute shadow-button bg-white rounded-lg z-50 md:right-0">
    {children}
  </div>)
};

export default SubMenu;