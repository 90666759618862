import React from 'react'
import { ReactComponent as CrossIcon } from "../icons/cross.svg";
import SubModal from "./SubModal";

const Modal = ({ show, children, handleCloseModal, canOutSideClick = false }: { show: boolean, children: React.ReactNode, handleCloseModal: () => void, canOutSideClick?: boolean }) => {

  const crossStyle: React.CSSProperties = {
    right: '.5rem',
    top: '.5rem',
  }

  return (show ? <div className="h-screen w-screen flex items-center justify-center bg-modal fixed z-20 left-0 top-0" >
    {canOutSideClick ? (
      <SubModal hideMenu={handleCloseModal}>
        <>
          <CrossIcon style={crossStyle} className="fill-current absolute cursor-pointer w-8 h-8" onClick={() => handleCloseModal()} />
          {children}
        </>
      </SubModal>
    ) : (
        <div className="bg-white w-full overflow-y-auto h-full md:h-auto md:w-2/4 lg:w-3/4 xl:w-2/4 rounded p-6 md:p-12 shadow-lg relative">
          <CrossIcon style={crossStyle} className="fill-current absolute cursor-pointer w-8 h-8" onClick={() => handleCloseModal()} />
          {children}
        </div>)}
  </div > : null);
}

export default Modal;