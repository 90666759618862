import React from 'react'
import { ThreadStatus as TStatus } from "@ticketrz/api-types/dist/thread/types";

const ThreadStatus = ({ status }: { status: TStatus | undefined }) => {

  const Status = () => {
    if (status === 'OPEN') {
      return (<div className="bg-green-200 w-auto rounded-full inline-block text-sm md:text-base px-2 md:px-4 flex flex-col justify-center">
        <p className="text-green-700 font-bold">En cours</p>
      </div>);
    } else if (status === 'CLOSED') {
      return (<div className="bg-red-200 rounded-full inline-block text-sm md:text-base px-2 md:px-4 flex flex-col justify-center">
        <p className="text-red-700 font-bold">Fermé</p>
      </div>);
    } else {
      return (<div className="bg-yellow-400 rounded-full inline-block text-sm md:text-base px-2 md:px-4 flex flex-col justify-center">
        <p className="text-yellow-700 font-bold">En attente</p>
      </div>)
    }
  }

  return (status ? <Status /> : null);
}

export default ThreadStatus;