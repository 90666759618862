import React, { useEffect, useContext } from 'react'
import DefaultEvent from "./event-types/DefaultEvent";
import StatusUpdatedEvent from './event-types/StatusUpdatedEvent';
import { animateScroll } from "react-scroll";
import { ThreadEvent, User } from "@ticketrz/api-types";
import { AuthContext } from "../auth/AuthContextProvider";
import { decodeJwt } from "../../common/auth/auth.service";
import Avatar from "../Avatar";

const ThreadEventList = ({ eventList, projectId, users }: { users: User[], eventList: ThreadEvent[], projectId: number | undefined }) => {
  const { state } = useContext(AuthContext);
  const userInfo = state.accessToken ? decodeJwt(state.accessToken) : null;

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "eventList",
      duration: 0,
    })
  }

  useEffect(() => {
    scrollToBottom();
  })

  const latestUsersRead = users
    .filter(user => user.threadReads && user.threadReads?.length > 0)
    .filter(user => user.threadReads![0].date >= eventList[eventList.length - 1]?.createdAt)
    .filter(user => user.id !== userInfo?.id)

  const EventTypeSelector = ({ projectId, previousEvent, event, users }: { projectId: number, previousEvent: ThreadEvent | null, event: ThreadEvent, users: User[] }) => {
    return (<div>
      {(() => {
        switch (event.type) {
          case "STATUS_UPDATED":
            return <StatusUpdatedEvent projectId={projectId} users={users} key={event.id} event={event} />
          case "MESSAGE_CREATED":
            return <DefaultEvent projectId={projectId} users={users} key={event.id} previousEvent={previousEvent} event={event} />;
          default:
            return null;
        }
      })()}
    </div>)
  }

  return (<div id="eventList" className="pt-4 pl-2 md:pl-4 pr-4 md:pr-8 overflow-y-auto h-72 md:h-auto">
    {projectId ? eventList.map((event, index) => <EventTypeSelector users={users} key={event.id} previousEvent={eventList[index -1] || null} event={event} projectId={projectId} />) : null}
    {latestUsersRead.length > 0 ? <div className="border-l-2 border-gray-500 relative pb-4 -mt-2 md:-mt-4 flex items-center justify-end">
      {latestUsersRead.map(u => <div className="-ml-2" key={u.id}><Avatar  firstname={u.firstname} lastname={u.lastname} size="sm" /></div>)}
      <p className="text-gray-600 text-xs md:text-base ml-1 md:ml-2">Vu</p>
    </div> : null}
  </div>);
};

export default ThreadEventList;