import axiosInstance from "../utils/axios";
import { GetUserThreadsDto, PaginatedList, Thread, CreateThreadDto, CreateMessageDto, File, GetThreadEventsDto, User, ThreadEventWithSignedUrl } from "@ticketrz/api-types"
import { ThreadStatus, GetThreadSummaryResponse, GetThreadsSortColumn } from "@ticketrz/api-types/dist/thread/types";

export const fetchUserThread = async (
  {
    search,
    status,
    projectId,
    limit,
    offset,
    order,
    sort,
  }: {
    projectId?: number,
    search?: string,
    status?: "OPEN" | "CLOSED" | "PENDING",
    limit: number,
    offset: number,
    order?: "ASC" | "DESC",
    sort?: GetThreadsSortColumn,
  }) => {
  const params: GetUserThreadsDto = {
    search: search && `%${search}%`,
    status,
    projectId,
    limit,
    offset,
    order,
    sort,
  };
  const response = await axiosInstance.get<PaginatedList<Thread>>(`/threads`, { params });
  return response.data;
}

export const createThread = (payload: CreateThreadDto) => axiosInstance.post<number>(
  '/threads',
  { ...payload }
)
  .then(response => response.data);

export const addMessageToThread = (id: number, payload: CreateMessageDto) => axiosInstance.post<number>(
  `/threads/${id}/messages`,
  { ...payload }
)
  .then(response => response.data);

export const getThread = (id: string) => axiosInstance.get<Thread>(`/threads/${id}`)
  .then(response => response.data);

export const getThreadEvents = async (id: string) => {
  const params: GetThreadEventsDto = {
    offset: 0,
    limit: 0,
    order: "ASC",
  }
  const response = await axiosInstance.get<PaginatedList<ThreadEventWithSignedUrl>>(`/threads/${id}/events`, { params });
  return response.data.list;
}

export const getThreadAttachments = async (id: string) => axiosInstance.get<PaginatedList<File & { signedPreviewUrl: string }>>(`/threads/${id}/files`)
  .then(response => response.data);

export const updateThread = async (id: number, status: ThreadStatus) => axiosInstance.patch(`/threads/${id}`, { status, })
  .then(response => response.data);

export const getThreadUsers = async (id: number) => axiosInstance.get<PaginatedList<User>>(`/threads/${id}/users`)
  .then(response => response.data.list);

export const updateThreadRead = async (id: number) => axiosInstance.post(`/threads/${id}/reads`)
  .then(response => response.data);

export const getThreadSummary = async (id: number) => axiosInstance.get<GetThreadSummaryResponse>(`/threads/${id}/summary`)
  .then(response => response.data);
