import React, { useCallback, useContext } from "react";
import { authWithAad, getAadAccessToken } from "../../common/auth/auth.service";
import { AuthContext } from "./AuthContextProvider";

const AadLoginButton = () => {
  const { dispatch } = useContext(AuthContext);

  const handleLogin = useCallback(async () => {
    const aadAccessToken = await getAadAccessToken();
    const { accessToken } = await authWithAad(aadAccessToken);
    dispatch({ type: "SET_TOKEN", payload: accessToken });
  }, [dispatch]);

  // TODO: add style
  return <button className="text-gray-500 mt-4 text-sm" onClick={handleLogin}>Connexion Yabawt</button>
};

export default AadLoginButton;
