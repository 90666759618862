import React, { useRef, ReactChild, ReactChildren } from "react";
import useOutsideClick from "../common/hooks/useOutsideClick";

const SubModal = ({ children, hideMenu }: { children: ReactChild | ReactChildren, hideMenu: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);

  const style: React.CSSProperties = {
    top: '40px',
  }

  useOutsideClick(ref, () => {
    hideMenu()
  });

  return (<div style={style} ref={ref} className="bg-white w-full overflow-y-auto h-full md:h-auto md:w-2/4 lg:w-3/4 xl:w-2/4 rounded p-6 md:p-12 shadow-lg relative">
    {children}
  </div>)
};

export default SubModal;
