import { Styles } from "react-select";

export const customStyles: Partial<Styles> = {
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isDisabled ? '' : isSelected ? '#F84601' : isFocused ? '#FFDACC' : '',
    color: isDisabled ? '' : isSelected ? '#FFFFFF' : isFocused ? '#F84601' : '',
    fontWeight: 400
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 400,
  }),
  valueContainer: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#FFFFFF' : '#edf2f7',
    borderWidth: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '.3rem',
    paddingTop: '.3rem',
    borderRadius: '.5rem 0 0 .5rem'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#edf2f7'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    borderWidth: '1px',
    borderRightWidth: 0,
    borderColor: '#A0AEC0',
    borderRadius: '.5rem 0 0 .5rem',
    color: '#4A5568',
    fontWeight: 700,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    borderWidth: '1px',
    borderLeftWidth: 0,
    borderColor: '#A0AEC0',
    borderRadius: '0 .5rem .5rem 0',
    color: '#4A5568'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: '#edf2f7',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '.1rem',
    paddingTop: '.1rem',
    height: '2.6rem',
    borderRadius: '0 .5rem .5rem 0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#a0aec0',
    opacity: '.5'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  control: (provided) => ({
    ...provided,
    borderWidth: 0,
    boxShadow: '0 0 0 1px #FFFFFF',
    borderColor: '#000000',
    "&:hover": {
      borderColor: '#000000',
    }
  })
}