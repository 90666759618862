import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as Sentry from "@sentry/browser";
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5cb5aeb0a1ab44fdb0d4efb6fe266ed7@sentry.io/4166939",
    environment: "front"
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
