import React, { useState, useCallback, useRef } from 'react';
import Button from "../forms/Button";
import FileUploader from "../file-uploader/FileUploader";
import UploadedFile from "../file-uploader/UploadedFile";
import Tiptap from "../../common/components/Tiptap";
import useGetEditor from "../../common/hooks/useGetEditor";

export interface IdentifiedFile {
  localId: string;
  data: File;
};

const ThreadEventForm = ({ submit, projectId }: { projectId: number | undefined, submit: (message: string, fileIds: number[] | undefined) => void }) => {
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const editor = useGetEditor({
    placeholder: "Votre message ici...",
    handleChange: (content) => setTextareaValue(content)
  })

  const uploadedFileIds = useRef<{ localId: string, databaseId: number }[]>([])

  const send = () => {
    setError(null);
    if (canSubmit()) {
      if (files.length !== uploadedFileIds.current.length) {
        // still waiting for files
        setError('Tous les fichiers n\'ont pas été téléversés');
        return;
      }

      submit(textareaValue, uploadedFileIds.current.length > 0 ? uploadedFileIds.current.map(({ databaseId }) => databaseId) : undefined);
      editor?.commands.clearContent()
      setTextareaValue('');
      setFiles([]);
      uploadedFileIds.current = [];
    }
  }

  const canSubmit = () => {
    setError(null);
    if (!textareaValue || textareaValue === '') {
      setError('Merci de saisir un message');
      return false;
    }
    return true;
  }

  const [files, setFiles] = useState<IdentifiedFile[]>([]);

  const addFile = (fileList: FileList | null) => {
    if (fileList) {
      const fileArray = [];
      for (let i = 0; i < fileList.length; i++) {
        const localId = `${Math.random()}-${Date.now()}`;
        const file = fileList.item(i);
        if (file) {
          fileArray.push({
            data: file,
            localId,
          });
        }
      }
      setFiles([...files, ...fileArray]);
    }
  }

  const removeFile = useCallback((localId: string) => {
    setFiles(currentFiles => currentFiles.filter(f => f.localId !== localId));
    uploadedFileIds.current = uploadedFileIds.current.filter(({ localId: id }) => id !== localId);
  }, []);

  const updateFile = useCallback((localId: string, databaseId: number) => {
    uploadedFileIds.current = [...uploadedFileIds.current, { localId, databaseId }]
  }, []);

  return (<div className="bg-white shadow-lg rounded px-4 py-2 md:mb-4">
    {editor && (
      <Tiptap addFile={addFile} editor={editor} send={send} />
    )}
    {
      error
        ? <div className="bg-red-200 rounded px-4 py-2 text-red-700"><p>{error}</p></div>
        : null
    }
    <div className="rounded mb-2 mt-4 flex items-center">
      {files.map((file) =>
        <UploadedFile
          projectId={projectId}
          key={file.localId}
          localId={file.localId}
          file={file.data}
          handleFileRemoved={removeFile}
          handleFileCompleted={updateFile}
        />
      )}
      {files.length > 0 ?
        <FileUploader mode="cta" handleFileAdd={(files) => addFile(files)} />
        : null}
    </div>

    <div className="flex justify-end">
      <Button text="Envoyer" bType="primary" onClick={() => send()} />
    </div>

  </div>);
}

export default ThreadEventForm;
