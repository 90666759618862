import React, { useState, useContext } from 'react'
import { ReactComponent as Logo } from "../assets/logo-yabawt.svg";
import EmailStep from "../components/auth/EmailStep";
import AadLoginButton from "../components/auth/AadLoginButton";
import SelectSendTypeStep from "../components/auth/SelectSendTypeStep";
import CodeStep from "../components/auth/CodeStep";
import { checkEmail, sendEmailCodeRequest, sendCode } from "../common/auth/auth.service";
import { AuthContext } from "../components/auth/AuthContextProvider";
import { AxiosError } from "axios";


interface LoginModel {
  email: string;
  mode: 'phone' | 'email';
  code: string;
}

const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const [model, setModel] = useState<LoginModel>({
    email: '',
    mode: 'email',
    code: '',
  });
  const [step, setStep] = useState(1)
  const [error, setError] = useState<string | null>(null)

  const style: React.CSSProperties = {
    backgroundImage: "url('/login-background.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover"
  };

  const nextStep = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (step === 1) {
      if (!model.email) {
        setError("Vous n'avez pas spécifié d'adresse email")
      }
      await checkEmail(model.email)
        .then(response => {
          setModel({ ...model, email: response.email });
          setStep(2);
        })
        .catch(_ => setError('Email invalide'));
    }
    if (step === 2) {
      sendCodeRequest(model.email);
    }
    if (step === 3) {
      await sendCode(model.email, model.code).then(response => {
        dispatch({ type: "SET_TOKEN", payload: response.accessToken });
      }).catch(_ => setError("Une erreur est survenu"));
    }
  }

  const sendCodeRequest = async (email: string) => {
    await sendEmailCodeRequest(email).then(_ => setStep(3))
      .catch((err: AxiosError) => {
        if (err.response?.status === 429) {
          setError("Limite d'envoi d'email atteinte, merci de réessayer dans quelque instant");
        } else {
          setError("Une erreur est survenu");
        }
      })
  }

  return (<div className="h-screen w-full flex items-center justify-center text-gray-800" style={style}>
    <div className="bg-white shadow-card w-5/6 md:w-1/3 rounded p-4 md:p-12 flex flex-col items-center">
      <Logo className="w-32 mb-6" />
      {step === 1 ?
        <>
          <EmailStep
            email={model.email}
            handleEmailChange={(email) => setModel({ ...model, email })}
            handleNext={(e) => nextStep(e)}
            error={error}
          />
          <div className="w-full text-center">
            <AadLoginButton />
          </div>
        </>
        : null
      }
      {step === 2 ?
        <SelectSendTypeStep handleNext={(e) => nextStep(e)} email={model.email} error={error} />
        : null
      }
      {step === 3 ?
        <CodeStep
          error={error}
          handleNext={(e) => nextStep(e)}
          handleCodeChange={(code) => setModel({ ...model, code })}
          handleResend={() => sendCodeRequest(model.email)}
        />
        : null
      }
    </div>
  </div>)
}

export default Login;