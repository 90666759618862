import React from 'react'
import InputSelect from "../forms/InputSelect";
import { PaginatedList, GetProjectsDto, Project } from "@ticketrz/api-types";
import axiosInstance from "../../common/utils/axios";
import { ValueType, OptionTypeBase } from "react-select";

const ProjectSelect = ({
  handleChange,
  value
}: {
  handleChange: (e: ValueType<OptionTypeBase>) => void,
  value: any
}) => {
  const PROJECT_PAGE_LIMIT = 15;

  const fetchProjects = async (inputValue: string) => {
    const params: GetProjectsDto = {
      search: inputValue ? `%${inputValue}%` : undefined,
      limit: PROJECT_PAGE_LIMIT,
    }
    return axiosInstance.get<PaginatedList<Project>>('/projects', { params })
      .then(response => response.data.list.map(project => ({
        label: `${project.client} - ${project.name}`,
        value: project.id,
      })));
  }
  return (<InputSelect value={value} handleChange={handleChange} options={fetchProjects} label="Projet" />);
}

export default ProjectSelect;