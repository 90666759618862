import React from 'react'
import AsyncSelect from 'react-select/async';
import { customStyles } from "../../assets/reactSelectStyle";
import { ValueType, OptionTypeBase } from "react-select";

export interface SelectValue extends OptionTypeBase {
  label: string;
  value: any;
}

const InputSelect = (
  { label, options, isMulti, handleChange, value }:
    {
      label: string,
      options: (inputValue: string) => void | Promise<any>,
      isMulti?: boolean,
      handleChange: (e: ValueType<OptionTypeBase>) => void
      value: any,
    }
) => {


  return (<label htmlFor="inputSelect" className="uppercase font-bold text-xs tracking-widest">
    {label}
    <AsyncSelect
      id="inputSelect"
      isMulti={isMulti}
      className="normal-case text-base font-normal mb-6 mt-1"
      styles={customStyles}
      defaultOptions
      value={value}
      onChange={(e) => handleChange(e)}
      placeholder="Rechercher..."
      loadOptions={options}
    />
  </label>);


};

export default InputSelect;