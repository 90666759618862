import React from "react";

const FilterBadge = ({ text, icon }: { text: string, icon?: JSX.Element }) => {
  return (
    <div className={`py-1 px-3 rounded-md border border-gray-500 ${icon && "flex items-center gap-1"}`}>
      {icon}
      <p className="text-sm text-gray-500 font-semibold">
        {text}
      </p>
    </div>
  );
}

export default FilterBadge;