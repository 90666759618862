import React, { useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import Threads from "./pages/Threads";
import Projects from "./pages/Projects";
import Thread from "./pages/Thread";

const Content = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(location.pathname === '/') {
      history.push('/threads');
    }
  }, [location.pathname, history])


  return (<div>
    <Switch>
      <Route exact path="/threads" children={<Threads />} />
      <Route exact path="/projects" children={<Projects />} />
      <Route exact path="/threads/:id" children={<Thread />} />
    </Switch>
  </div>)
}

export default Content;