import React from 'react'
import InputSelect from "../forms/InputSelect";
import { PaginatedList, User, GetUsersDto } from "@ticketrz/api-types";
import axiosInstance from "../../common/utils/axios";
import { OptionTypeBase, ValueType } from "react-select";

const UserSelect = ({
  handleChange,
  value,
}: {
  handleChange: (e: ValueType<OptionTypeBase>) => void,
  value: any,
}) => {
  const USER_PAGE_LIMIT = 15;

  const fetchUsers = async (inputValue: string) => {
    const params: GetUsersDto = {
      search: inputValue ? `%${inputValue}%` : undefined,
      limit: USER_PAGE_LIMIT,
      type: "user",
    }
    return axiosInstance.get<PaginatedList<User>>('/users', { params })
      .then(response =>
        [
          { label: 'Nouveau collaborateur', value: 0 },
          ...response.data.list.map(
            user => ({
              label: `${user.firstname} ${user.lastname} - ${user.email}`,
              value: user.id,
            })
          )
        ]
      );
  }

  return (<InputSelect value={value} handleChange={handleChange} options={fetchUsers} label="Collaborateur client" />);
}

export default UserSelect;