import { Reducer } from "react";
import axiosInstance from "../utils/axios";

export interface AuthState {
  accessToken?: string;
}

export interface SetTokenAction {
  type: "SET_TOKEN";
  payload: string;
}

export interface RemoveTokenAction {
  type: "REMOVE_TOKEN";
}

export type AuthAction =
  | SetTokenAction
  | RemoveTokenAction;

export const authReducer: Reducer<AuthState, AuthAction> = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case "SET_TOKEN":
      console.log("SET_TOKEN");
      // TODO: use middleware if we switch to redux
      axiosInstance.defaults.headers = {
        ...axiosInstance.defaults.headers,
        authorization: `Bearer ${action.payload}`,
      };
      return { ...state, accessToken: action.payload };
    case "REMOVE_TOKEN":
      console.log("REMOVE_TOKEN")
      delete axiosInstance.defaults.headers.authorization;
      const { accessToken, ...rest } = state;
      return rest;
    default:
      return state;
  }
};
