import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import AuthContextProvider from "./components/auth/AuthContextProvider";
import Layout from "./Layout";

const App: React.FC = () => {
  return (
    <IntlProvider locale="fr">
      <AuthContextProvider>
        <Router>
          <Layout />
        </Router>
      </AuthContextProvider>
    </IntlProvider>
  );
}

export default App;
