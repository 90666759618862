import React from 'react'

const InputText = (
  { label, placeholder, optional, ...props }:
    {
      label?: string;
      placeholder?: string;
      optional?: boolean;
    } & React.InputHTMLAttributes<HTMLInputElement>
) => {
  return (<label htmlFor="input" className="uppercase font-bold text-xs tracking-widest">
    {label}
    {optional ? <span className="ml-2 text-xs normal-case text-gray-500">Facultatif</span> : null}
    <input
      id="input"
      {...props}
      placeholder={placeholder}
      className="mb-4 md:mb-6 placeholder-gray-500 mt-1 block bg-gray-200 px-4 py-2 w-full text-base rounded-lg border border-transparent focus:bg-white focus:border-gray-400"
    />
  </label>);
};

export default InputText;