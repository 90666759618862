import React from "react";
import useBoundStore from "../../store";
import FilterProject from "./FilterProject";
import FilterSort from "./FilterSort";
import FilterStatus from "./FilterStatus";

const ThreadFilters = () => {
  const { filter, sort } = useBoundStore()
  return (
    <div className="flex items-center gap-3 py-3">
      <FilterProject projectId={filter.projectId} />
      <FilterStatus status={filter.status} />
      <FilterSort sort={sort.sort} sortOrder={sort.order} />
    </div>
  );
}

export default ThreadFilters;