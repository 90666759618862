import { create } from "zustand"
import { persist } from "zustand/middleware"
import createThreadFilterSlice, { ThreadFilterSlice } from "./threadFilter/slice"
import createThreadDisplayingSlice, { ThreadDisplayingSlice } from "./threadsDisplaying/slice"
import createThreadSortSlice, { ThreadSortSlice } from "./threadSort/slice"

const useBoundStore = create<ThreadSortSlice & ThreadFilterSlice & ThreadDisplayingSlice>()(
  persist(
    (...a) => (
      {
        ...createThreadFilterSlice(...a),
        ...createThreadSortSlice(...a),
        ...createThreadDisplayingSlice(...a)
      }
    ),
    {
      name: "preferences"
    }
  )
)

export default useBoundStore