import { UserTokenPayload } from "@ticketrz/api-types";
import React from 'react';
import { NavLink } from 'react-router-dom';
import AccountMenu from "./AccountMenu";

export interface ResponsiveMenuProps {
  handleRouteClick: () => void
  userInfo: UserTokenPayload
}

const ResponsiveMenu = ({ handleRouteClick, userInfo }: ResponsiveMenuProps) => {

  return (<div className="bg-white h-full fixed inset-0 z-50 mt-20">
    <div className="flex flex-col">
      <div className="flex items-center justify-center">
        <AccountMenu firstname={userInfo.firstname} lastname={userInfo.lastname} className="relative my-6"/>
      </div>
      <NavLink to="/threads" onClick={handleRouteClick} activeClassName="bg-orange-lighter text-orange" className="outline-none px-2 py-2 my-2 flex flex-col justify-center">
        <span className="font-bold px-10">Echanges</span>
      </NavLink>
      <NavLink to="/projects" onClick={handleRouteClick} activeClassName="bg-orange-lighter border-orange text-orange" className="outline-none px-2 py-2 my-2 flex flex-col justify-center">
        <span className="font-bold px-10">Project</span>
      </NavLink>
    </div>
  </div>)
}

export default ResponsiveMenu;