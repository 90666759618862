import React from 'react'
import InputText from "../forms/InputText";
import Button from "../forms/Button";

const EmailStep = (
  {
    email,
    error,
    handleNext,
    handleEmailChange
  }: {
    email: string,
    error: string | null,
    handleNext: (e: React.FormEvent<HTMLFormElement>) => void,
    handleEmailChange: (email: string) => void
  }) => {

  return (<div className="w-full">
    <p className="text-center mb-6 font-bold">Bienvenue sur votre espace client</p>
    <form onSubmit={(e) => handleNext(e)}>
      <InputText
        label="Email"
        type="email"
        required
        placeholder="exemple@email.com"
        value={email}
        onChange={(e) => handleEmailChange(e.target.value)}
      />
      {error ? <div className="bg-red-200 rounded px-4 py-2 text-red-700">
        {error}
      </div> : null}
      <div className="flex items-center justify-center mt-4 md:mt-12">
        <Button text="Suivant" bType="primary" className="w-32" type="submit" />
      </div>
    </form>
  </div>)
}

export default EmailStep;