import React, { useEffect, useState } from 'react';
import { fetchProjects } from "../../common/services/project.service";
import { Project } from "@ticketrz/api-types";
import useBoundStore from "../../store";

const ThreadFilter = () => {
  const [projects, setProjects] = useState<Project[]>([])
  useEffect(() => {
    fetchProjects({ search: undefined, limit: 0, offset: 0 }).then(response => {
      setProjects(response.list);
    });
  }, []);

  const { filter: currentFilter, setFilter } = useBoundStore();

  return (<div className="w-64">
    <div className="rounded-lg flex flex-col items-start">
      <p className="uppercase text-xs font-bold w-full tracking-widest py-2 px-4 border-b border-gray-300 bg-gray-200 rounded-t-lg">états</p>
      <button
        className={`${currentFilter.status === 'OPEN' ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setFilter({ filter: { ...currentFilter, status: "OPEN" } })}
      >Ouvert</button>
      <button
        className={`${currentFilter.status === 'PENDING' ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setFilter({ filter: { ...currentFilter, status: "PENDING" } })}
      >En attente de réponse</button>
      <button
        className={`${currentFilter.status === 'CLOSED' ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setFilter({ filter: { ...currentFilter, status: "CLOSED" } })}
      >Fermé</button>
      <button
        className={`${!currentFilter.status ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setFilter({ filter: { ...currentFilter, status: undefined } })}
      >Pas de filtre</button>
    </div>
    <div className="rounded-lg flex flex-col mt-2">
      <p className="uppercase text-xs font-bold tracking-widest py-2 px-4 border-b border-gray-300 bg-gray-200">Projet</p>
      {projects.map(p => <button
        key={p.id}
        className={`${currentFilter.projectId === p.id ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setFilter({ filter: { ...currentFilter, projectId: p.id } })}
      >
        {p.name}
      </button>)}
      <button
        className={`${!currentFilter.projectId ? 'text-orange' : null} text-left w-full hover:bg-orange-lighter hover:text-orange px-4 py-2`}
        onClick={() => setFilter({ filter: { ...currentFilter, projectId: undefined } })}>

        Tous les projets
      </button>
    </div>
  </div>);
}

export default ThreadFilter;
