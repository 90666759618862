import React, { useCallback, useRef, useState } from 'react';
import Button from "../forms/Button";
import ProjectSelect from "../project/ProjectSelect";
import InputText from "../forms/InputText";
import { SelectValue } from "../forms/InputSelect";
import { createThread } from "../../common/services/thread.service";
import { ThreadPriority } from "@ticketrz/api-types/dist/thread/types";
import { useHistory } from "react-router-dom";
import FileUploader from "../file-uploader/FileUploader";
import { IdentifiedFile } from "./ThreadEventForm";
import UploadedFile from "../file-uploader/UploadedFile";
import Tiptap from "../../common/components/Tiptap";
import useGetEditor from "../../common/hooks/useGetEditor";
// import InputSelect from "../forms/InputSelect";
// import { OptionsType } from "react-select";

interface ThreadFormModel {
  projectId: SelectValue | null;
  subject: string;
  message: string;
  error: string | null;
}

const ThreadForm = ({ handleBackEvent }: { handleBackEvent: () => void }) => {
  const [files, setFiles] = useState<IdentifiedFile[]>([]);
  const uploadedFileIds = useRef<{ localId: string, databaseId: number }[]>([])
  const history = useHistory();
  const editor = useGetEditor({
    placeholder: "Un premier message ?",
    handleChange: (content) => setModel({ ...model, message: content })
  })
  const [model, setModel] = useState<ThreadFormModel>({
    message: '',
    projectId: null,
    subject: '',
    error: null,
  })

  const addFile = (fileList: FileList | null) => {
    if (fileList) {
      const fileArray = [];
      for (let i = 0; i < fileList.length; i++) {
        const localId = `${Math.random()}-${Date.now()}`;
        const file = fileList.item(i);
        if (file) {
          fileArray.push({
            data: file,
            localId,
          });
        }
      }
      setFiles([...files, ...fileArray]);
    }
  }

  const removeFile = useCallback((localId: string) => {
    setFiles(currentFiles => currentFiles.filter(f => f.localId !== localId));
    uploadedFileIds.current = uploadedFileIds.current.filter(({ localId: id }) => id !== localId);
  }, []);

  const updateFile = useCallback((localId: string, databaseId: number) => {
    uploadedFileIds.current = [...uploadedFileIds.current, { localId, databaseId }]
  }, []);

  const submit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!model.projectId) {
      setModel({ ...model, error: 'Vous devez selectionner un projet' });
      return;
    }

    if (model.subject.length > 100) {
      setModel({ ...model, error: 'Le sujet doit contenir au maximum 100 caractères.' })
      return;
    }

    if (!model.message) {
      setModel({ ...model, error: 'Veuillez renseigner un message.' })
      return;
    }

    const response = await createThread({ priority: ThreadPriority.LOW, projectId: model.projectId.value, subject: model.subject, message: model.message, ...uploadedFileIds.current.length > 0 ? { fileIds: uploadedFileIds.current.map(({ databaseId }) => databaseId) } : {} })
      .catch(_ => setModel({ ...model, error: 'Une erreur est survenue.' }))

    if (response) {
      editor?.commands.clearContent();
      clearForm();
      history.push(`/threads/${response}`)
    }
  }

  const clearForm = () => {
    setModel({
      message: '',
      projectId: null,
      subject: '',
      error: null,
    });
  }

  return (<div>
    <h4 className="text-4xl font mb-8">Nouvelle discussion</h4>
    <form onSubmit={(e) => submit(e)}>
      <ProjectSelect value={model.projectId} handleChange={(e) => setModel({ ...model, projectId: e as SelectValue })} />
      <InputText
        value={model.subject}
        onChange={(e) => setModel({ ...model, subject: e.target.value })}
        label="Sujet"
        required
        placeholder="Saisir le sujet de la nouvelle discussion"
      />
      {editor && (
        <Tiptap editor={editor} addFile={addFile} send={submit} isDisable={model.projectId?.value ? false : true} />
      )}
      <div className="rounded mb-2 mt-4 flex items-center">
        {files.map((file) =>
          <UploadedFile
            projectId={model.projectId?.value}
            key={file.localId}
            localId={file.localId}
            file={file.data}
            handleFileRemoved={removeFile}
            handleFileCompleted={updateFile}
          />
        )}
        {files.length > 0 ?
          <FileUploader mode="cta" handleFileAdd={(files) => addFile(files)} />
          : null}
      </div>
      {
        model.error ?
          <div className="bg-red-200 rounded px-4 py-2 text-red-700"><p >{model.error}</p></div>
          : null
      }
      <div className="flex items-center justify-between mt-12">
        <Button text="Annuler" bType="default" onClick={() => handleBackEvent()} />
        <Button text="Créer discussion" type="submit" bType="primary" />
      </div>
    </form>
  </div>);
};

export default ThreadForm;