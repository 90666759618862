import React from 'react'
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron-down.svg";
import { ReactComponent as LeftArrow } from '../../icons/left-arrow.svg'
import { ReactComponent as BoxIcon } from "../../icons/box.svg";

export type IconName = 'plus' | 'chevron-down' | 'left-arrow' | 'box';

export interface ButtonProps {
  text: string;
  icon?: IconName;
  bType: 'primary' | 'secondary' | 'default'
}

const Button = ({ text, icon, bType, ...props }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {

  const iconType: { [key in IconName]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    'plus': PlusIcon,
    'chevron-down': ChevronDownIcon,
    'left-arrow': LeftArrow,
    'box': BoxIcon,
  }

  const DynamicIcon = ({ name, className }: { name: IconName, className: string }) => {
    let Icon = iconType[name];
    return <Icon className={className} />;
  }

  const buttonClass = {
    'primary': 'bg-orange text-white hover:shadow-button px-4',
    'secondary': 'bg-white text-orange border border-orange hover:shadow-button px-4',
    'default': 'text-orange'
  }

  return (<button {...props} className={`${buttonClass[bType]} ${props.className} rounded-full h-8 md:h-9 font-bold flex items-center justify-center`}>
    {icon ?
      <div>
        <DynamicIcon className="fill-current mr-2" name={icon} />
      </div>
      : null}
    <span className="text-sm md:text-base">{text}</span>
  </button>);
}

export default Button;
