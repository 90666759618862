import React from 'react'
import Button from "../forms/Button";

const CodeStep = (
  {
    handleNext,
    handleCodeChange,
    handleResend,
    error,
  }: {
    handleNext: (e: React.FormEvent<HTMLFormElement>) => void,
    handleCodeChange: (code: string) => void,
    handleResend: () => void,
    error: string | null,
  }) => {

  return (<div className="flex flex-col items-center">
    <p className="font-bold uppercase text-xs tracking-widest w-full">Code</p>
    <form onSubmit={(e) => handleNext(e)} className="w-full">
      <div className="flex items-center justify-center mt-4">
        <input type="text" onChange={(e) => handleCodeChange(e.target.value)} required className="placeholder-gray-500 uppercase text-center font-bold w-full block bg-gray-300 px-2 py-2 text-base rounded-lg border border-transparent focus:bg-white focus:border-gray-400" />
      </div>
      {error ? <div className="bg-red-200 rounded px-4 py-2 mt-2 text-red-700">
        {error}
      </div> : null}
      <div className="flex items-center justify-center mt-2">
        <Button type="button" text="Renvoyer code" bType="secondary" onClick={() => handleResend()} className="w-48" />
        <Button text="Suivant" bType="primary" type="submit" className="ml-2 w-32" />
      </div>
    </form>
  </div>);
}

export default CodeStep;