import React, { useState, useEffect } from 'react'
import Avatar from "../../Avatar";
import { FormattedDate } from "react-intl";
import { ThreadEvent, User, ThreadEventWithSignedUrl, File } from "@ticketrz/api-types";
import FileIcon from "../../file-uploader/FileIcon";
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';
import { download } from "../../../common/utils/download";
import anchorme from 'anchorme';
import Modal from "../../Modal";
import { ReactComponent as ViewMoreIcon } from '../../../icons/eye.svg';
import { isImage } from "../../../common/utils/isImage";
import { getFileUrl } from "../../../common/services/project.service";
import Loader from "../../Loader";

const DefaultEvent = ({ event, previousEvent, projectId, users }: { event: ThreadEventWithSignedUrl, previousEvent: ThreadEvent | null, projectId: number, users: User[] }) => {
  const dotStyle: React.CSSProperties = {
    left: '-9px',
    top: '-9px',
  }
  const [currentFile, setCurrentFile] = useState<File & { signedPreviewUrl: string } | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!currentFile || !projectId) {
      return;
    }
    getFileUrl({
      projectId,
      fileId: currentFile.id,
      previewMode: true
    }).then(r => setFileUrl(r))
  }, [currentFile, projectId])


  const handleOpenModal = async (file: File & { signedPreviewUrl: string }) => {
    setCurrentFile(file);
  }

  const closeModal = () => {
    setCurrentFile(null);
    setFileUrl(null);
  }

  const user = users.find(u => u.id === event.userId);

  const grouped = previousEvent && previousEvent.userId === event.userId
    ? ((new Date(event.createdAt).getTime() - new Date(previousEvent?.createdAt).getTime()) / 60000) < 1
    : false;

  return (
    <>
      <div className={`border-l-2 border-gray-500 relative pl-6 ${grouped ? 'pb-4 md:pb-6' : 'py-4 md:py-6'}`}>
        {grouped ? null : <div style={dotStyle} className="absolute flex items-center">
          <div className="bg-white rounded-full w-4 h-4 flex items-center justify-center">
            <span className="bg-orange rounded-full w-2 h-2">&nbsp;</span>
          </div>
          <p className="ml-2 text-gray-500 text-xs md:text-sm">
            <FormattedDate
              value={event.createdAt}
              year="numeric"
              day="numeric"
              month="long"
              hour="numeric"
              minute="numeric"
            />
          </p>
        </div>}
        <div className="bg-white rounded shadow-lg">
          <div className="flex p-2 md:p-4">
            {event.message ? <p
              className="text-sm pb-2 overflow-x-auto"
              dangerouslySetInnerHTML={{
                __html: anchorme(event.message?.replace(/(\r\n|\r|\n)/g, '<br />'), {
                  attributes: [{
                    name: "class",
                    value: "text-orange-light underline",
                  }, {
                    name: "target",
                    value: "_blank"
                  }]
                })
              }}
            /> : null}
          </div>
          {event.files && event.files.length > 0 && (
            <div className="grid grid-cols-2 lg:grid-cols-4 mx-4 gap-4 pt-8 border-t">
              {event.files?.map((f, index) =>
                <React.Fragment key={index}>
                  <div className="flex flex-col">
                    <div className="relative w-auto h-20 group transition border rounded-t-md duration-300 ease-in-out cursor-pointer" onClick={() => handleOpenModal(f)}>
                      {
                        isImage(f)
                          ? <img className="object-cover h-full w-full rounded-t-md" src={f.signedPreviewUrl} alt={f.name} />
                          : <div className="w-full h-full flex items-center justify-center border relative overflow-hidden rounded-t-md">
                            <FileIcon className="text-gray-200 w-32 h-32 transform translate-y-6 absolute" error={false} fileType={f.mimeType} />
                          </div>
                      }
                      <div className="absolute inset-0 w-full h-full bg-black rounded-t-md transition duration-300 ease-in-out opacity-0 group-hover:opacity-25"></div>
                      <div className="p-2 bg-orange rounded-full opacity-0 scale-0 cursor-pointer group-hover:opacity-100 group-hover:scale-100 transition duration-300 ease-in-out absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <ViewMoreIcon className="w-8 h-8 fill-current font-light text-white" />
                      </div>
                    </div>

                    <div
                      onClick={() => download(projectId, f.id, f.name)}
                      key={index}
                      className="group flex items-center justify-between cursor-pointer transition duration-300 ease-in-out px-2 py-1 bg-gray-300 rounded-b-md  hover:bg-orange">
                      <p className="text-gray-700 group-hover:text-white text-sm transition duration-300 ease-in-out truncate" title={f.name}>{f.name}</p>
                      <DownloadIcon className="block w-3 h-3 flex-shrink-0 fill-current font-light transition duration-300 ease-in-out text-gray-600 group-hover:text-white" />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
          <div className="flex justify-end  p-2 md:p-4 ">
            <div className="flex items-center">
              {user ? <Avatar firstname={user.firstname} lastname={user.lastname} size="sm" /> : null}
              <p className="ml-2 hidden md:block text-sm">{user?.firstname} {user?.lastname}</p>
            </div>
          </div>
        </div>
        <Modal show={!!currentFile} handleCloseModal={() => closeModal()} canOutSideClick>
          {
            fileUrl && currentFile
              ? isImage(currentFile)
                ? <img src={fileUrl} alt="" className="flex items-center justify-center object-contain max-h-96 w-full" />
                : <iframe title="pdf" src={fileUrl} style={{ width: "100%", height: "600px" }}></iframe>
              : <div className="flex flex-col items-center justify-center md:mt-64">
                <Loader />
              </div>
          }
        </Modal>
      </div>
    </>
  )
}

export default DefaultEvent;
