import React, { useContext } from 'react'
import Header from "./components/Header";
import Content from "./Content";
import Login from "./pages/Login";
import { AuthContext } from "./components/auth/AuthContextProvider";

const Layout = () => {
  const { state } = useContext(AuthContext);

  return (state.accessToken ? <div className="text-gray-800">
  <Header />
  <Content />
</div> : <Login />);
}

export default Layout;