import axiosInstance from "../utils/axios";

export const createUser = (payload: { firstname: string, lastname: string, email: string }) => axiosInstance.post<number>(
  '/users',
  { ...payload },
);

export const attachUserToProject = (userId: number, projectId: number ) => axiosInstance.post(
  `/users/${userId}/projects/${projectId}`,
);
