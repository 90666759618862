import React from 'react'
import { ReactComponent as DefaultFileIcon } from "../../icons/file.svg";
import { ReactComponent as PictureIcon } from "../../icons/picture.svg";
import { ReactComponent as WarningIcon } from "../../icons/warning.svg";

const FileIcon = ({ error, fileType, className }: { error: boolean, fileType: string, className: string }) => {
  if (error) {
    return <WarningIcon className="fill-current text-red-500 h-6 w-6" />
  }
  switch (fileType) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/svg':
      return <PictureIcon className={`${className} fill-current h-6 w-6`} />;
    default:
      return <DefaultFileIcon className={`${className} fill-current h-6 w-6`} />
  }
}

export default FileIcon;