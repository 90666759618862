import { getFileUrl } from "../../common/services/project.service";

export const download = async (projectId: number, fileId: number, fileName: string) => {
  if (projectId) {
    const url = await getFileUrl({ projectId, fileId });
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }
};
