import React, { useCallback, useEffect, useState } from "react";
import { fetchProjects } from "../../common/services/project.service";
import FilterBadge from "./FilterBadge";

const FilterProject = ({ projectId }: { projectId: number | undefined }) => {
  const [projectName, setProjectName] = useState<string | undefined>()

  const getProjects = useCallback(async () => {
    return fetchProjects({ search: undefined, limit: 0, offset: 0 }).then(response => {
      return response.list.find((project) => project.id === projectId)?.name;
    })
  }, [projectId])

  useEffect(() => {
    if (!projectId) {
      setProjectName("Tous les projets")
      return;
    }
    getProjects().then(response => {
      setProjectName(response)
    })
  }, [getProjects, projectId])

  return (
    <>
      {projectName && (
        <FilterBadge text={projectName} />
      )}
    </>
  )

}

export default FilterProject