import { ThreadFilterAction, ThreadFilterState } from '../common/types';
import { StateCreator } from 'zustand';

export type ThreadFilterSlice = ThreadFilterState & ThreadFilterAction

const createThreadFilterSlice: StateCreator<ThreadFilterState & ThreadFilterAction> = (
  (set) => ({
    filter: {
      status: undefined,
      projectId: undefined,
    },
    setFilter: (payload) => set(() => ({ ...payload })),
  })
)

export default createThreadFilterSlice
