import React, { useMemo } from "react";
import FilterBadge from "./FilterBadge";
import { ReactComponent as ArrowUp } from "../../icons/bar-arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../icons/bar-arrow-down.svg";

const FilterSort = ({ sort, sortOrder }: { sort: string | undefined, sortOrder: string | undefined }) => {
  const sortText = useMemo(() => {
    switch (sort) {
      case 'createdAt':
        return "Trier par date de création de la discussion"
      case 'threadEvents':
        return "Trier par date du dernier message"
      case 'subject':
        return "Trier par ordre alphabétique du sujet"
      default:
        break;
    }
  }, [sort])

  const sortOrderIcon = useMemo(() => {
    switch (sortOrder) {
      case 'ASC':
        return <ArrowUp className="text-gray-500 w-5" />
      case 'DESC':
        return <ArrowDown className="text-gray-500 w-5" />
      default:
        break;
    }
  }, [sortOrder])
  return (
    <>
      {sortText && (
        <FilterBadge text={sortText} icon={sortOrderIcon} />
      )}
    </>
  )

}

export default FilterSort