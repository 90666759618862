import { ThreadDisplayingState, ThreadDisplayingAction } from '../common/types';
import { StateCreator } from 'zustand';

export type ThreadDisplayingSlice = ThreadDisplayingState & ThreadDisplayingAction

const createThreadDisplayingSlice: StateCreator<ThreadDisplayingState & ThreadDisplayingAction> = (
  (set) => ({
    displaying: "grid",
    setDisplaying: (payload) => set(() => ({ ...payload }))
  })
)

export default createThreadDisplayingSlice